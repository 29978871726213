import styled from "@emotion/styled";
import { Box, Chip, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import UserAvatar from "../avatars/UserAvatar";
import { useTranslation } from "react-i18next";
import { AssistantMessage, ChatType } from "../../types/chatBot";

import OwlAvatarIcon from "../../icons/OwlAvatarIcon";
import VendorAvatar from "../avatars/VendorAvatar";
import { useNavigate } from "react-router-dom";
import ContractAvatar from "../avatars/ContractAvatar";
import DateTimeTranslate from "../DateTimeTranslate";
import useAuth from "../../hooks/useAuth";
import useBotChat from "../../hooks/useBotChat";
import Markdown from "react-markdown";
import { ArticleOutlined } from "@mui/icons-material";
import { epochToDate } from "../../redux/slices/indexApiSlice";

const Message = styled.div<{ position: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.position === "left" ? "flex-start" : "flex-end"};
  padding: 0 20px 20px;
  text-align: ${(props) => props.position};
`;

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.neutral.secondary
      : props.theme.palette.neutral.primary};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.neutral.contrastText
      : props.theme.palette.neutral.contrastText};
  border-radius: 3px;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => (props.highlighted ? 0 : props.theme.spacing(6))};
  margin-left: ${(props) => (props.highlighted ? props.theme.spacing(6) : 0)};
  ${(props) => props.theme.shadows[1]};
`;

export const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

interface IChatMessageProps {
  chatMessage: Partial<AssistantMessage>;
  ref?: any;
  maxWidth?: number;
}

export const getMessage = (chatMessage: Partial<AssistantMessage>): string => {
  if (!chatMessage.content || chatMessage?.content?.length === 0) return "";

  const annotations = chatMessage?.content[0]?.text?.annotations;

  const response = chatMessage.content[0]?.text.value;

  let message;
  try {
    const match = response.match(/```json\n([\s\S]*?)\n```/);
    if (match) {
      const jsonText = match[1];

      const jsonStr = jsonText.replace(/^```json\n|\n```$/g, "");
      const json = JSON.parse(jsonStr);
      message = json?.answer;
    } else {
      message = response;
    }
  } catch (e) {
    message = response;
  }

  for (const annotation of annotations) {
    // remove certain indexes from the message
    const start = annotation.start_index;
    const end = annotation.end_index;
    // const textToReplace = message.slice(start, end);
    const textToReplace = annotation.text;

    message = message.replace(textToReplace, "");
  }

  return message;
};

export const getFollowUpQuestions = (
  chatMessage: Partial<AssistantMessage>
): string[] => {
  try {
    if (!chatMessage.content || chatMessage?.content?.length === 0) return [];

    let response = chatMessage.content[0]?.text.value;

    const match = response.match(/```json\n([\s\S]*?)\n```/);
    if (match) {
      const jsonText = match[1];

      const jsonStr = jsonText.replace(/^```json\n|\n```$/g, "");
      const json = JSON.parse(jsonStr);

      return json?.follow_up_questions;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

function ChatMessage(props: IChatMessageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    botName,
    setOpen: setShowBotChat,
    addedMessage,
    setAddedMessage,
  } = useBotChat();

  const { chatMessage, ref, maxWidth } = props;
  const type = null;
  const name = chatMessage.role === "user" ? user.displayName : botName;

  const message = getMessage(chatMessage);
  const listOfChoices: any[] = []; // chatMessage.listofChoices;
  const position = chatMessage.role === "user" ? "right" : "left";

  return (
    <Message position={position} ref={ref}>
      <Box
        display="flex"
        justifyContent="center"
        justifySelf="center"
        alignItems="center"
      >
        {position == "left" ? (
          <OwlAvatarIcon></OwlAvatarIcon>
        ) : (
          <UserAvatar></UserAvatar>
        )}
        <ChatMessageBubbleName variant="body1" ml={2}>
          {name}
        </ChatMessageBubbleName>
      </Box>
      <strong className="no-cursor">
        <ChatMessageBubble highlighted={position === "right"}>
          {position === "right" && (
            <Typography variant="body2">
              <Markdown
                components={{
                  p: ({ node, ...props }) => (
                    <p
                      style={{
                        marginBlockStart: "0.5rem",
                        marginBlockEnd: "0.5rem",
                      }}
                      {...props}
                    />
                  ),
                }}
              >
                {message}
              </Markdown>
            </Typography>
          )}

          {position === "left" && (
            <Box>
              <Typography variant="body2">
                <Markdown
                  components={{
                    p: ({ node, ...props }) => (
                      <p
                        style={{
                          marginBlockStart: "0.5rem",
                          marginBlockEnd: "0.5rem",
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {message === ""
                    ? t("I don't have an answer to that yet")
                    : message}
                </Markdown>
              </Typography>

              {listOfChoices && listOfChoices?.length > 0 && (
                <Grid container direction="column" mb={2} spacing={1}>
                  {/* {listOfChoices.map((choice, i: number) => {
                    return "contractID" in choice
                      ? choice.contractID.map((c) => c)
                      : choice.vendorID.map((v) => v);
                  })} */}

                  {listOfChoices?.map((choice, i: number) => {
                    if (type === ChatType.SUGGESTED_VENDORS) {
                      return (
                        "vendorID" in choice && (
                          <Grid key={`choice-${i}`}>
                            <VendorAvatar vendorId={choice.vendorID} big />

                            {choice.description && (
                              <Typography variant="body1" pt={2}>
                                {choice.description}
                              </Typography>
                            )}

                            {choice.email && (
                              <Typography variant="body1">
                                {choice.email}
                              </Typography>
                            )}

                            {choice.address && (
                              <Typography variant="body1">
                                {choice.address}
                              </Typography>
                            )}

                            {choice.website && (
                              <Link
                                href={
                                  choice.website?.indexOf("https://") === -1
                                    ? `https://${choice.website}`
                                    : choice.website
                                }
                                target="_blank"
                              >
                                {choice.website}
                              </Link>
                            )}
                          </Grid>
                        )
                      );
                    } else if (type === ChatType.SUGGESTED_CONTRACTS) {
                      return (
                        choice &&
                        typeof choice === "string" && (
                          <Grid key={`choice-${i}`}>
                            <Grid container direction="column" spacing={1}>
                              <Grid key={`choice-${i}`}>
                                <Chip
                                  label={
                                    <ContractAvatar contractId={choice} small />
                                  }
                                  color="primary"
                                  icon={<ArticleOutlined />}
                                  onClick={() => {
                                    navigate(`/contract/${choice}`);
                                  }}
                                  sx={{
                                    maxWidth: maxWidth ? maxWidth - 100 : 300,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      );
                    }
                  })}
                </Grid>
              )}
            </Box>
          )}
        </ChatMessageBubble>
      </strong>
      {chatMessage.created_at && (
        <Typography variant="subtitle2">
          <DateTimeTranslate
            date={new Date(epochToDate(chatMessage.created_at))}
            format="ago"
          />
        </Typography>
      )}
    </Message>
  );
}
export default ChatMessage;
