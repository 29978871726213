import React, { useEffect } from "react";

import {
  Box,
  Alert,
  AlertTitle,
  LinearProgress,
  IconButton,
  ListItemText,
  ListItemAvatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Popover,
  Typography,
  ListItemButton,
  Paper,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useContract from "../hooks/useContract";
import { ContractProgress, IContract } from "../types/contract";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import {
  Check,
  ExpandMore as ExpandMoreIcon,
  WarningAmber,
} from "@mui/icons-material";
import { differenceInMinutes, isSameDay } from "date-fns";
import OwlAvatarIcon from "../icons/OwlAvatarIcon";
import AskMeAnything from "../pages/components/AskMeAnything";

function AnalyzingContractsLoader({
  contract,
  variant,
}: {
  contract?: IContract;
  variant?: "circular" | "linear";
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [totalProgress, setTotalProgress] = React.useState(0);

  const { analyzedContracts, isAnalyzing, analyzingNumber, analyzingSuccess } =
    useContract();

  const [analyzingContract, setAnalyzingContract] =
    React.useState<ContractProgress>();
  const [analyzingContracts, setAnalyzingContracts] = React.useState<
    ContractProgress[]
  >([]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (
      analyzedContracts?.length > 0 ||
      (analyzingNumber && analyzingNumber > 0)
    ) {
      setAnalyzingContracts(analyzedContracts);

      if (contract) {
        const newAnalyzingContract = analyzedContracts.find(
          (c) => c.contract?.id === contract.id
        );

        setAnalyzingContract(newAnalyzingContract);
        setTotalProgress(newAnalyzingContract?.progress || 0);
      } else {
        const totalProgress = analyzedContracts.reduce(
          (acc, contract) => acc + (contract?.progress || 0),
          0
        );

        setTotalProgress(totalProgress / analyzedContracts.length);
      }
    }
  }, [
    analyzedContracts,
    isAnalyzing,
    analyzingNumber,
    analyzingSuccess,
    contract,
  ]);

  const ageInMinutes = analyzingContract?.initiationDate
    ? differenceInMinutes(
        new Date(),
        new Date(analyzingContract.initiationDate)
      )
    : null;

  const finishedToday = analyzingContract?.completionDate
    ? analyzingContract?.progress === 100 &&
      isSameDay(new Date(analyzingContract.completionDate), new Date())
    : false;

  const contractsRemaining = analyzedContracts?.filter(
    (c) => c.progress >= 0 && c.progress < 100
  );

  const contractsCompleted = analyzedContracts?.filter(
    (c) => c.progress === 100
  );

  const contractsFailed = analyzedContracts?.filter((c) => c.progress === -1);

  // if (variant === "circular" && contractsRemaining.length === 0) return null;

  if (
    variant !== "circular" &&
    !analyzingContract &&
    contractsRemaining?.length === 0
  ) {
    // do not show anything if there are no contracts being analyzed
    // except if we are on a contract detail page
    return null;
  }

  const body = (
    <Box>
      {isAnalyzing ? (
        <>
          {contract ? ( // this will be displayed on the contract detail page
            <>
              {analyzingContract && (
                <>
                  {analyzingContract.progress === -1 ? (
                    <Alert severity="warning">
                      <AlertTitle>{t("NotRead")}</AlertTitle>
                      {t("Contract not read")}
                    </Alert>
                  ) : analyzingContract?.progress === 0 &&
                    analyzingContract.promptsRead?.length === 0 &&
                    ageInMinutes! > 10 ? (
                    <Alert severity="warning">
                      <AlertTitle>{t("NotRead")}</AlertTitle>
                      {t("Contract not read")}
                    </Alert>
                  ) : finishedToday ? (
                    <Alert severity="success">
                      <AlertTitle>{t("Reading done")}</AlertTitle>
                      {t("Reading success")}

                      <Box py={1}>
                        <Typography>
                          {t("Started")}:{" "}
                          {t("date.relative", {
                            date: new Date(analyzingContract.initiationDate),
                          })}
                        </Typography>

                        <Typography>
                          {t("Finished")}:{" "}
                          {t("date.relative", {
                            date: new Date(analyzingContract.completionDate),
                          })}
                        </Typography>
                      </Box>
                    </Alert>
                  ) : (
                    analyzingContract?.progress >= 0 &&
                    analyzingContract?.progress < 100 && (
                      <Alert severity="info">
                        <AlertTitle>
                          {t("Reading", {
                            percentage: Math.round(totalProgress * 10) / 10,
                          })}
                        </AlertTitle>
                        {t("Reading document")}
                      </Alert>
                    )
                  )}

                  {analyzingContract?.progress >= 0 &&
                    analyzingContract?.progress < 100 && (
                      <LinearProgress
                        variant="determinate"
                        value={totalProgress}
                      />
                    )}
                </>
              )}
            </>
          ) : (
            <Accordion sx={{ my: 0, marginBottom: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {contractsRemaining.length === 0 &&
                contractsFailed.length > 0 ? (
                  <Alert
                    severity="error"
                    sx={{ width: "100%", backgroundColor: "transparent" }}
                  >
                    {t("ContractsNotRead", { count: contractsFailed.length })}
                  </Alert>
                ) : contractsRemaining.length === 0 &&
                  contractsCompleted.length > 0 ? (
                  <Alert
                    severity="success"
                    sx={{ width: "100%", backgroundColor: "transparent" }}
                  >
                    {t("Contracts have been read", {
                      count: contractsCompleted.length,
                    })}
                  </Alert>
                ) : (
                  <Grid container direction="column" size={12} gap={6}>
                    {/* {t("Reading", {
                        percentage: Math.round(totalProgress * 10) / 10,
                      })} */}

                    {t("Reading_contracts", {
                      count: contractsRemaining.length,
                    })}

                    {/* <LinearProgressWithLabel
                      variant="determinate"
                      value={totalProgress}
                    /> */}
                  </Grid>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <List disablePadding>
                  {analyzingContracts.map((contract, i) => (
                    <>
                      <ListItemButton
                        key={contract.contract?.id}
                        onClick={() =>
                          navigate(`/contract/${contract.contract?.id}`)
                        }
                      >
                        <ListItemAvatar>
                          {contract.progress === -1 ? (
                            <WarningAmber />
                          ) : contract.progress === 100 ? (
                            <Check />
                          ) : (
                            <CircularProgressWithLabel
                              variant={
                                !contract.progress
                                  ? "indeterminate"
                                  : "determinate"
                              }
                              value={contract.progress || 0}
                              size="2rem"
                            />
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={contract.contract?.name}
                          secondary={t("date.ago", {
                            date:
                              contract.progress >= -1 && contract.progress < 100
                                ? new Date(contract.initiationDate)
                                : new Date(contract.completionDate),
                          })}
                        />
                      </ListItemButton>

                      {i < analyzingContracts.length - 1 && (
                        <Divider component="li" />
                      )}
                    </>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      ) : analyzingSuccess ? (
        <Alert severity="success">
          <AlertTitle>{t("Success")}</AlertTitle>
          {t("Contracts have been read")}
        </Alert>
      ) : (
        <span />
      )}

      {/* {analyzeError && (
          <Alert severity="error">
            <AlertTitle>{t("An error occurred!")}</AlertTitle>
            {analyzeError?.data && JSON.stringify(analyzeError.data)}
            {!analyzeError?.data && JSON.stringify(analyzeError)}
          </Alert>
        )} */}
    </Box>
  );

  return (
    <Box>
      {analyzingContracts?.length > 0 && !isNaN(totalProgress) && (
        <>
          {variant === "circular" ? (
            <>
              <IconButton onClick={handleClick}>
                {contractsRemaining.length === 0 ? (
                  <OwlAvatarIcon />
                ) : (
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={totalProgress}
                    size="2rem"
                  />
                )}
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    backgroundColor: theme.palette.background.default,
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <AskMeAnything
                    placeholder={"Ask a question about your contracts"}
                  />
                  {body}
                </Paper>
              </Popover>
            </>
          ) : (
            body
          )}
        </>
      )}
    </Box>
  );
}

export default AnalyzingContractsLoader;
