import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Chip,
  Fade,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import DocumentViewer from "../../../components/viewers/DocumentViewer";
import useAuth from "../../../hooks/useAuth";
import {
  useAddTermsAndConditionsDocumentContentMutation,
  useAddTermsAndConditionsDocumentMutation,
  useGetTermsAndConditionsDocumentsQuery,
  useLazyGetTermsAndConditionsQuery,
} from "../../../redux/slices/indexApiSlice";
import { DialogMode } from "../../../types/dialogmode";
import { IDocument } from "../../../types/document";
import AIContractDetailSidebar, {
  SideBarTabIndex,
} from "../contracts/components/AIContractDetailSidebar";
import { IPrompt } from "../../../types/prompt";
import { Paragraph } from "../../../types/aiOutput";
import { MuiFileInput } from "mui-file-input";
const mimeDb = require("mime-db");

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function getFileTypeName(mimeType: string) {
  const mimeEntry = mimeDb[mimeType];

  if (mimeEntry && mimeEntry.extensions) {
    const fileExtension = mimeEntry.extensions[0];
    return fileExtension.toUpperCase();
  }

  return "Unknown";
}

const TermsAndConditions = ({
  selectedContractId,
  selectedPrompt,
  hideHeader,
}: {
  selectedContractId?: string;
  selectedPrompt?: IPrompt;
  hideHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { user, method } = useAuth();

  const { data: documents = [], isSuccess: documentsLoaded } =
    useGetTermsAndConditionsDocumentsQuery();

  const [getTermsAndConditions, { data: aiOutputs = [] }] =
    useLazyGetTermsAndConditionsQuery();

  const [
    addTermsAndConditions,
    {
      isLoading: addingDocument,
      isSuccess: documentAdded,
      data: addedDocument,
      reset: resetDocument,
    },
  ] = useAddTermsAndConditionsDocumentMutation();

  const [
    uploadDocumentContent,
    {
      isLoading: isUploading,
      isSuccess: documentUploaded,
      reset: resetContent,
    },
  ] = useAddTermsAndConditionsDocumentContentMutation();

  const [sideBarTab, setSideBarTab] = useState(SideBarTabIndex.BASIC_DETAILS);
  const [document, setDocument] = useState<IDocument>();
  const [pdfFileError, setPdfFileError] = useState("");
  const [paragraph, setParagraph] = useState<Paragraph>();

  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    if (documents.length > 0) {
      // select the main contract
      const mainDoc = documents[0];
      if (mainDoc) {
        setDocument(mainDoc);
      } else {
        setDocument(documents[0]);
      }
    }
  }, [documents]);

  useEffect(() => {
    if (document) {
      getTermsAndConditions(document);
    }
  }, [document]);

  const switchToDocument = (doc: IDocument) => {
    setDocument(doc);
  };

  useEffect(() => {
    if (documentAdded && addedDocument && file) {
      console.log("document added", document);

      // Create a new FormData object
      const formData = new FormData();
      // Append the File object to the FormData object
      formData.append("file", file);

      uploadDocumentContent({
        documentId: addedDocument.id,
        file: formData,
      })
        .unwrap()
        .then((payload: any) => {
          console.log("fulfilled", payload);
          setFile(null);
          resetContent();
          resetDocument();
        })
        .catch((error: any) => {
          console.error("rejected", error);

          const errorMessage = error?.data?.message
            ? error.data.message
            : error?.error?.message
            ? error.error.message
            : error?.error?.error;

          setPdfFileError(errorMessage);
        });
    }
  }, [documentAdded, addedDocument, file]);

  useEffect(() => {
    if (documentUploaded && file) {
    }
  }, [documentUploaded]);

  const allowedFileTypes = ["pdf"]; //["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "jpg", "jpeg", "png", "gif", "txt", "eml"];

  const handleChange = (newValue: any) => {
    let selectedFile = newValue;
    if (selectedFile) {
      const fileTypeName = getFileTypeName(selectedFile.type);
      if (
        selectedFile &&
        allowedFileTypes.some(
          (ext) => ext.toLocaleUpperCase() === fileTypeName.toLocaleUpperCase()
        )
      ) {
        setFile(selectedFile);

        const termsDoc: IDocument = {
          name: selectedFile.name,
          type: "terms-and-conditions",
          origin: "upload",
          location: "blobstorage",
        };
        addTermsAndConditions(termsDoc);
      } else {
        setPdfFileError(`${fileTypeName} is not allowed for upload`);
      }
    } else {
      setFile(null);
    }
  };

  return (
    <React.Fragment>
      {hideHeader ? null : (
        <>
          <Helmet title={t("Terms and conditions")!} />

          <Grid justifyContent="space-between" container wrap="nowrap">
            <Grid size={12} sx={{ flexGrow: 2 }}>
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/contracts" color="inherit">
                  <Typography variant="h3" gutterBottom display="inline">
                    {t("Terms and conditions")}
                  </Typography>
                </Link>

                <Typography
                  variant="h3"
                  gutterBottom
                  display="inline"
                  color="primary"
                >
                  {document?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Link component={NavLink} to="/contracts">
          {t("Contracts")}
        </Link>
        <Typography>{contract?.name}</Typography>
      </Breadcrumbs> */}

          <Divider my={6} />
        </>
      )}

      <Paper
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          // height: "100%",
          // overflow: isUpSm ? "hidden" : "auto",
        }}
        variant="outlined"
      >
        <MuiFileInput
          sx={{ mb: "10px" }}
          value={file}
          onChange={handleChange}
          helperText=""
          placeholder={t("Select a file")!}
        />

        <Fade in={pdfFileError !== ""} unmountOnExit mountOnEnter>
          <Alert severity="warning">{pdfFileError}</Alert>
        </Fade>

        <Grid container direction="column" gap={3} sx={{ flexGrow: 1 }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            spacing={3}
            flexGrow={1}
          >
            <Grid
              container
              size={12}
              direction="row"
              justifyContent="space-between"
            >
              {documents?.length > 0 && (
                <Grid container gap={2}>
                  {documents?.map((att, i) => (
                    <Chip
                      label={`${t("Attachment")} ${(i + 1).toString()}: ${
                        att.name
                      }`}
                      variant={att.id === document?.id ? "filled" : "outlined"}
                      color={att.id === document?.id ? "primary" : "default"}
                      onClick={(e) => switchToDocument(att)}
                    />
                  ))}
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" size={12} flexGrow={1}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Paper variant="outlined">
                  <DocumentViewer
                    viewMode="withMenu"
                    // contract={contract}
                    document={document}
                    paging={false}
                    disableAppBar
                    aiOutputs={aiOutputs}
                  />
                </Paper>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                {document && (
                  <AIContractDetailSidebar
                    mode={DialogMode.Edit}
                    documents={documents}
                    document={document}
                    aiOutputs={aiOutputs}
                    setSelectedParagraph={setParagraph}
                    sideBarTab={sideBarTab}
                    setSideBarTab={setSideBarTab}
                    switchToDocument={(id: string) => {
                      const doc = documents.find((d) => d.id === id);
                      if (doc) {
                        setDocument(doc);
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackbar}
        onClose={handleClose}
        message={t("Analysis requested")}
        key={"bottomright"}
        autoHideDuration={5000}
        color="primary"
      /> */}
    </React.Fragment>
  );
};

export default TermsAndConditions;
