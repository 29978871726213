import React, { useEffect, useState } from "react";

import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import useAuth from "../../hooks/useAuth";
import { IContractCategory } from "../../types/contractCategory";
import { IGraphUser } from "../../types/user";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchemaContractCategory = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().optional(),
});

interface IContractCategoryFormProps {
  mode: DialogMode;
  addContractCategory: (contractCategory: IContractCategory) => Promise<void>;
  updateContractCategory: (
    contractCategory: IContractCategory
  ) => Promise<void>;
  contractCategory?: IContractCategory;
}

function ContractCategoryForm({ ...props }: IContractCategoryFormProps) {
  const { t } = useTranslation();
  // create a ref
  // const formikRef = useRef<FormikProps<any>>(null);

  const { user } = useAuth();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === "add") {
        await props.addContractCategory(values);

        //   resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === "edit") {
        await props.updateContractCategory(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: "Mode undefined" });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.ContractCategory });
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   if (props.submit) {
  //     // if (formikRef.current) {
  //     //   // submit the form
  //     //   formikRef?.current?.handleSubmit();
  //     // }
  //   }
  // }, [props.submit]);

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const blankContractCategory: IContractCategory = {
    id: "",
    body: "",
    author: user?.id,
    title: "",
  };

  const initialValues: IContractCategory = props.contractCategory
    ? props.contractCategory
    : blankContractCategory;

  const fieldVariant = "standard"; // "outlined";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaContractCategory}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6}>
          {errors && !isValid && !status?.sent && (
            <Grid>
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            </Grid>
          )}

          <Grid width="100%">
            <Form id="contract-category-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid>
                  <TextField
                    name="title"
                    label={t("Title")}
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("title", text);
                    }}
                    variant={
                      touched.title && errors.title
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={false}
                  />
                </Grid>

                <Grid height={500}>
                  <TextField
                    name="body"
                    label={t("Description")}
                    value={values.body}
                    error={Boolean(touched.body && errors.body)}
                    fullWidth
                    // helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("body", text);
                    }}
                    variant={
                      touched.body && errors.body
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default ContractCategoryForm;
