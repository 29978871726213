import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
// import ContractDetails from "./pages/pages/ContractDetails";
import ContractList from "./pages/main/contracts/ContractList";
import Settings from "./pages/admin/settings/Settings";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import { Route, Routes } from "react-router-dom";
import ContractCalendar from "./pages/main/calendar/ContractCalendar";
import VendorList from "./pages/main/vendors/VendorList";
import VendorDetails from "./pages/main/vendors/VendorDetails";
import TemplatesList from "./pages/main/contract-templates/TemplatesList";
import KPIList from "./pages/main/kpi/KPIList";

import NotificationList from "./pages/main/notifications/NotificationList";
import ActivityList from "./pages/admin/activities/ActivityList";
import TrustedPartners from "./pages/admin/trusted-partners/TrustedPartners";
// import ManagedContractList from "./pages/pages/ManagedContractList";
// import ManagedContractDetails from "./pages/pages/ManagedContractDetails";
import GuestGuard from "./components/guards/GuestGuard";
// import ContractNew from "./pages/main/ContractNew";

import AIContractSign from "./pages/main/contracts/signing/AIContractSign";
import AIContractDetail from "./pages/main/contracts/AIContractDetail";

import Page403 from "./pages/auth/Page403";
import Page410 from "./pages/auth/Page410";
import SharePointConnections from "./pages/admin/sharepoint-connections/SharePointConnections";
import Outlook from "./pages/main/connections/Outlook";
import DataLocation from "./pages/admin/data-location/DataLocation";

import ContractsUnderManagementList from "./pages/main/contracts-under-management/ContractsUnderManagementList";
import ClientsUnderManagement from "./pages/main/contracts-under-management/ClientsUnderManagement";
import ConnectedDrives from "./pages/admin/connected-drives/ConnectedDrives";

import PromptsList from "./pages/main/prompts/PromptsList";
import DataGridContractPromptResponses from "./components/datagrids/DataGridContractPromptResponses";
import StrengthMatrix from "./pages/main/dashboards/Default/StrengthMatrix";
import Folders from "./pages/admin/folders/Folders";
import ContractCategoriesList from "./pages/main/prompts/ContractCategoriesList";
import TermsAndConditions from "./pages/main/terms-and-conditions/TermsAndConditions";

// Dashboard components
const Default = async(() => import("./pages/main/dashboards/Default"));
const Analytics = async(() => import("./pages/main/dashboards/Analytics"));

const routes = [
  // {
  //   path: "/",
  //   element: <PresentationLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
      {
        path: "default",
        element: (
          <AuthGuard>
            <Default />
          </AuthGuard>
        ),
      },
      {
        path: "strength-matrix",
        element: (
          <AuthGuard>
            <StrengthMatrix />
          </AuthGuard>
        ),
      },
      // {
      //   path: "saas",
      //   element: (
      //     <AuthGuard>
      //       <SaaS />
      //     </AuthGuard>
      //   ),
      // },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
    ],
  },
  {
    path: "/contracts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ContractList />,
      },
      // {
      //   path: ":status",
      //   element: <ContractList />,
      // },
      {
        path: ":status",
        element: <ContractList />,
      },
      {
        path: "folders/:folder",
        children: [
          {
            path: "",
            element: <ContractList />,
          },
          {
            path: ":contractId",
            element: <AIContractDetail />,
          },
          {
            path: ":contractId/:pageMode",
            element: <AIContractDetail />,
          },
        ],
      },
    ],
  },
  {
    path: "/contract",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: "new",
      //   element: <ContractNew />,
      // },
      {
        path: ":contractId",
        element: <AIContractDetail />,
      },
      {
        path: ":contractId/:pageMode",
        element: <AIContractDetail />,
      },
    ],
  },
  {
    path: "/signature",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <AIContractSign />,
      },
      // {
      //   path: "500",
      //   element: <Page500 />,
      // },
      // {
      //   path: "403",
      //   element: <AIContractSignaturePage403 />,
      // },
      // {
      //   path: "410",
      //   element: <AIContractSignaturePage410 />,
      // },
    ],
  },
  {
    path: "/contracts-under-management",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: ":clientId/contracts/:contractId/edit",
      //   element: (
      //     <AuthGuard>
      //       <AIContractStepper />
      //     </AuthGuard>
      //   ),
      //   children: [
      //     {
      //       path: ":stepNum",
      //       element: (
      //         <AuthGuard>
      //           <AIContractStepper />
      //         </AuthGuard>
      //       ),
      //     },
      //   ],
      // },
      {
        path: ":clientId/contracts/:contractId",
        element: <AIContractDetail />,
      },
      {
        path: ":clientId/contracts/:contractId/:pageMode",
        element: <AIContractDetail />,
      },
      {
        path: ":clientId/contracts",
        element: <ContractsUnderManagementList />,
      },
      {
        path: ":clientId",
        element: <ClientsUnderManagement />,
      },
      {
        path: "",
        element: <ClientsUnderManagement />,
      },
    ],
  },
  {
    path: "/vendors",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <VendorList />,
      },
    ],
  },
  {
    path: "/vendor",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "detail",
        element: <VendorDetails />,
      },
      {
        path: ":vendorId",
        element: <VendorDetails />,
      },
      {
        path: ":vendorId/:detailMode",
        element: <VendorDetails />,
      },
    ],
  },
  {
    path: "/libraries",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "contract-categories",
        element: <ContractCategoriesList />,
      },
      {
        path: "kpi",
        element: <KPIList />,
      },
      {
        path: "prompts",
        element: <PromptsList />,
      },
      {
        path: "contract-template",
        element: <TemplatesList />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
        children: [
          {
            path: ":documentId",
            element: <TermsAndConditions />,
          },
        ],
      },
    ],
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Settings />,
      },
    ],
  },
  {
    path: "calendar",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ContractCalendar />,
      },
      {
        path: ":id",
        element: <ContractCalendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "activitylog",
        element: <ActivityList />,
      },
      {
        path: "sharepoint-connections",
        element: <SharePointConnections />,
      },
      {
        path: "trusted-partners",
        element: <TrustedPartners />,
      },
      {
        path: "data-location",
        element: <DataLocation />,
      },
      {
        path: "connected-drives",
        element: <ConnectedDrives />,
      },
      {
        path: "folders",
        element: <Folders />,
      },
    ],
  },
  // {
  //   path: "changelog",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  {
    path: "/notifications",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <NotificationList />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "403",
        element: <Page403 />,
      },
      {
        path: "410",
        element: <Page410 />,
      },
      {
        path: "410",
        element: <Page410 />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
