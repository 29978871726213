import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { english } from "./translations/english";
import { dutch } from "./translations/dutch";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  format as formatDate,
  formatRelative,
  formatDistance,
  isDate,
  isToday,
  isTomorrow,
} from "date-fns";
import { enGB, nl } from "date-fns/locale"; // import all locales we need
import { t } from "i18next";

const locales: any = { enGB, nl }; // used to look up the required locale
const resources = {
  en: {
    translation: english,
  },
  nl: {
    translation: dutch,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value) && lng && format) {
          const locale = locales[lng];
          if (format === "short") return formatDate(value, "P", { locale });
          if (format === "long") return formatDate(value, "PPPP", { locale });
          if (format === "relative")
            return formatRelative(value, new Date(), { locale });
          if (format === "dateOnly") {
            if (isToday(new Date(value))) return t("Today").toLowerCase();
            if (isTomorrow(new Date(value))) return t("Tomorrow").toLowerCase();
            return formatDate(value, "PP", { locale });
          }
          if (format === "ago")
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });
          return formatDate(value, format, { locale });
        }
        return value;
      },
    },
    detection: {
      convertDetectedLanguage: (lang: any) => {
        // If the detected language does not exist in the language list, the language will be set to default is dutch.
        if (!Object.keys(resources).includes(lang)) {
          return "nl";
        }
        return lang;
      },
    },
  });
