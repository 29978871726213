import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button as MuiButton,
  Typography,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useUpdateContractMutation } from "../../../../redux/slices/indexApiSlice";
import { Store } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { ContractStatus, IContract } from "../../../../types/contract";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import VendorAvatar from "../../../../components/avatars/VendorAvatar";
import { NumericFormat } from "react-number-format";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { useParams } from "react-router-dom";

const TypographyLight = styled(Typography)`
  color: #646464;
`;

const Centered = styled.div`
  text-align: center;
`;

const Button = styled(MuiButton)(spacing);

const steps = [
  ContractStatus.DRAFT,
  ContractStatus.PUBLISHED,
  ContractStatus.SIGNING,
  ContractStatus.SIGNED,
  ContractStatus.ACTIVE,
];

type ContractMetadataProps = {
  contract: IContract;
};

const ContractMetadata: React.FC<ContractMetadataProps> = ({
  contract,
}: ContractMetadataProps) => {
  const { t } = useTranslation();
  const { clientId, contractId, pageMode, status } = useParams();

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [updateContract, { isSuccess: isUpdated, reset: resetUpdate }] =
    useUpdateContractMutation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contractId === contract?.id) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [contract, contractId]);

  useEffect(() => {
    if (isUpdated) {
      resetUpdate();
    }
  }, [isUpdated]);

  // useEffect(() => {
  //   if (contract.status) {
  //     setActiveStep(steps.indexOf(contract.status));
  //   } else {
  //     setActiveStep(0);
  //   }
  // }, [contract.status]);

  useEffect(() => {
    if (contract.status) {
      // mark all steps up to the active step as completed
      const activeStep = steps.indexOf(contract.status);
      const newCompleted = completed;
      for (let i = 0; i <= activeStep; i++) {
        newCompleted[i] = true;
      }
      setCompleted(newCompleted);
    } else {
      setCompleted({});
    }
  }, [contract.status]);

  return (
    contract && (
      <Box mb={6}>
        {/* <Typography variant="h6" mb={2}>
          {t("Contract Information")}
        </Typography> */}

        {!contract?.isAnalyzed && !contract?.summary && !contract.subject && (
          <CustomNoRowsOverlay label={t("Contract has not been read")} />
        )}

        {/* {contract.status && (
          <Box mb={3}>
            <TypographyLight variant="caption">{t("Status")}</TypographyLight>

            <ContractStatusStepper contract={contract} />
          </Box>
        )} */}

        {contract.countryOfJurisdiction && (
          <Box mb={3}>
            <TypographyLight variant="caption">
              {t("Country of jurisdiction")}
            </TypographyLight>
            <Typography variant="body1">
              {t(contract.countryOfJurisdiction)}
            </Typography>
          </Box>
        )}
        {contract.type && (
          <Box mb={3}>
            <TypographyLight variant="caption">{t("Type")}</TypographyLight>
            <Typography variant="body1">{t(contract.type)}</Typography>
          </Box>
        )}
        {contract.subject && (
          <Box mb={3}>
            <TypographyLight variant="caption">{t("Subject")}</TypographyLight>
            <Typography variant="body1">{contract.subject}</Typography>
          </Box>
        )}
        {contract.summary && (
          <Box mb={3}>
            <TypographyLight variant="caption">{t("Summary")}</TypographyLight>
            <Typography variant="body1">{contract.summary}</Typography>
          </Box>
        )}

        {(contract?.vendorId ||
          contract?.vendorAddress ||
          contract?.explanations?.seller ||
          contract?.startDate ||
          contract?.endDate) && (
          <Box mb={3}>
            <Grid container>
              <Grid size={{ xs: 12, md: 6 }}>
                <TypographyLight variant="caption">
                  {t("Vendor")}
                </TypographyLight>

                {/* {contract?.vendorId && (
              <VendorAvatar vendorId={contract.vendorId} big />
            )} */}

                {contract?.vendorId ? (
                  <ListItemButton
                    href={`/vendor/${contract.vendorId}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Store />
                    </ListItemIcon>
                    {!loading && (
                      <ListItemAvatar
                        sx={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <VendorAvatar vendorId={contract.vendorId} small />
                      </ListItemAvatar>
                    )}
                    {/* <ListItemText>
                    <Typography variant="overline">{vendor?.name}</Typography>
                    {vendor?.address && (
                      <Typography variant="overline">
                        {vendor?.address}
                      </Typography>
                    )}
                  </ListItemText> */}
                  </ListItemButton>
                ) : (
                  <>
                    <Typography variant="body1">
                      {contract.vendorName && contract?.vendorName}
                      <br />
                      {contract.vendorAddress && contract?.vendorAddress}
                    </Typography>
                  </>
                )}
              </Grid>
              {contract?.vendorAddress && (
                <Grid size={{ xs: 12, md: 6 }}>
                  <TypographyLight variant="caption">
                    {t("Address")}
                  </TypographyLight>
                  <Typography variant="body1">
                    {contract?.vendorAddress}
                  </Typography>
                </Grid>
              )}

              {contract?.explanations?.seller && (
                <Grid size={12}>
                  <Alert
                    sx={{ mt: 2 }}
                    severity="info"
                    variant="outlined"
                    icon={<OwlAvatarIcon />}
                  >
                    <Typography variant="body1">
                      {contract?.explanations.seller}
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        {(contract.startDate || contract.endDate) && (
          <Box mb={3}>
            <Grid container>
              <Grid size={{ xs: 12, md: 6 }}>
                <TypographyLight variant="caption">
                  {t("Start date")}
                </TypographyLight>
                {contract.startDate && (
                  <Typography variant="body1">
                    {format(new Date(contract.startDate), "P")}
                  </Typography>
                )}
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <TypographyLight variant="caption">
                  {t("End date")}
                </TypographyLight>
                {contract.endDate && (
                  <Typography variant="body1">
                    {format(new Date(contract.endDate), "P")}
                  </Typography>
                )}
              </Grid>

              {contract.explanations?.dates && (
                <Grid size={12}>
                  <Alert
                    sx={{ mt: 2 }}
                    severity="info"
                    variant="outlined"
                    icon={<OwlAvatarIcon />}
                  >
                    <Typography variant="body1">
                      {contract.explanations.dates}
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        {(contract.noticePeriod || contract.explanations?.noticePeriod) && (
          <Box mb={3}>
            <Grid container>
              <Grid size={{ xs: 12, md: 6 }}>
                <TypographyLight variant="caption">
                  {t("Notice period")}
                </TypographyLight>
                {contract.noticePeriod && (
                  <Typography variant="body1">
                    {contract.noticePeriod?.value}{" "}
                    {t(contract.noticePeriod?.unit)}
                  </Typography>
                )}

                {contract.explanations?.noticePeriod && (
                  <Alert
                    sx={{ mt: 2 }}
                    severity="info"
                    variant="outlined"
                    icon={<OwlAvatarIcon />}
                  >
                    <Typography pt={1} variant="body1">
                      {contract.explanations.noticePeriod}
                    </Typography>
                  </Alert>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {(contract.budget || contract.explanations?.financials) && (
          <Box mb={3}>
            <TypographyLight variant="caption">{t("Value")}</TypographyLight>

            <Grid container alignContent="center">
              {contract.budget?.currency ? (
                new Intl.NumberFormat(navigator.language, {
                  style: "currency",
                  currency: contract.budget?.currency,
                  compactDisplay: "long",
                  maximumFractionDigits: 0,
                }).format(contract.budget?.amount)
              ) : (
                <NumericFormat
                  value={contract.budget?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"? "}
                />
              )}
            </Grid>

            {contract.budget?.paymentTerms && (
              <Typography pt={1} variant="body1">
                {contract.budget.paymentTerms}
              </Typography>
            )}
            {contract.explanations?.financials && (
              <Alert
                sx={{ mt: 2 }}
                severity="info"
                variant="outlined"
                icon={<OwlAvatarIcon />}
              >
                <Typography pt={1} variant="body1">
                  {contract.explanations.financials}
                </Typography>
              </Alert>
            )}
          </Box>
        )}

        {/* {contract?.contactPersons && contract.contactPersons.length > 0 && (
          <Box mb={3}>
            <TypographyLight variant="caption">
              {t("Contact persons")}
            </TypographyLight>

            <Grid container spacing={3} pt={2}>
              {contract?.contactPersons?.map((person, i) => {
                return (
                  <Grid key={`contactPerson-${i}-${person.email}`}>
                    <Alert severity="info" icon={<Person />} variant="outlined">
                      <Typography variant="body1">{person.name}</Typography>
                      <Typography variant="body1">{person.email}</Typography>
                      <Typography variant="body1">{person.phone}</Typography>
                      <Typography variant="body1">{person.role}</Typography>
                    </Alert>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )} */}

        {contract.id && (
          <Box mb={3}>
            <TypographyLight variant="caption">
              {t("Contract ID")}
            </TypographyLight>
            <Typography variant="body1">{t(contract.id)}</Typography>
          </Box>
        )}

        {/* {contract.risks?.map((risk: { risk: string; recommendation: string }) => { */}
        {/* {contract.risks?.map((risk) => {
        return (
          <Box mb={3}>
            <Box mb={1}>
              <TypographyLight variant="caption">{t("Risk")}</TypographyLight>
              <Typography variant="body1">{risk.risk}</Typography>
            </Box>
            <Box>
              <TypographyLight variant="caption">
                {t("Recommendation")}
              </TypographyLight>
              <Typography variant="body1">{risk.recommendation}</Typography>
            </Box>
          </Box>
        );
      })} */}
      </Box>
    )
  );
};

export default ContractMetadata;
