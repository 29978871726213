import React from "react";

import { AutoAwesome } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../../../types/document";
import { useEstimateCostsMutation } from "../../../../redux/slices/aiSlice";
import { useLazyGetDriveItemContentAsPDFQuery } from "../../../../redux/slices/graphApiSlice";
import { IContract } from "../../../../types/contract";
import AnalysisConfirmationDialog from "../../../../components/dialogs/AnalysisConfirmationDialog";

interface ISidebarRiskAnalysisProps {
  isDownLg: boolean;
  analyzing: boolean;
  isAnalyzed: boolean;
  contract: IContract;
  document: IDocument;
  onAnalysis?: () => void;
  hideTitle?: boolean;
}
function SidebarRiskAnalysis({
  document,
  isDownLg,
  analyzing,
  onAnalysis,
  isAnalyzed,
  contract,
  hideTitle,
}: ISidebarRiskAnalysisProps) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [estimateCosts, ,] = useEstimateCostsMutation();

  const [getDriveItemContentAsPDF] = useLazyGetDriveItemContentAsPDFQuery();

  const confirmAnalysis = () => {
    // setOpenDialog(true);
  };

  const handleAnalysis = () => {
    setOpenDialog(false);
    onAnalysis && onAnalysis();
  };

  const requestCostEstimation = async () => {
    if (document) {
      if (document.location === "sharepoint") {
        // we need to pass a downloadUrl
        const response = await getDriveItemContentAsPDF(document);
        if (response.data) {
          const downloadUrl = response.data;
          estimateCosts({ ...document, downloadUrl });
        }
      } else {
        estimateCosts(document);
      }
    }
  };

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems={isDownLg ? "flex-start" : "flex-end"}
    >
      <Box width="100%">
        {!hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Risk analysis")}
          </Typography>
        )}

        {!isAnalyzed && (
          <>
            <Typography variant="body1">
              {t(
                "The AI service will analyze the contract and extract the following information"
              )}
              :
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>{t("Risks")}</li>
                <li>{t("Recommendations")}</li>
              </ul>
            </Typography>
          </>
        )}

        <Box mt={3}>
          <LoadingButton
            variant="contained"
            loading={analyzing}
            onClick={handleAnalysis}
          >
            <AutoAwesome style={{ marginRight: "8px" }} />
            {t("Start AI analysis")}
          </LoadingButton>
        </Box>
      </Box>
      {!isAnalyzed && (
        <LoadingButton
          variant="contained"
          sx={{ alignSelf: "start", marginTop: 3 }}
          onClick={handleAnalysis}
          loading={analyzing}
        >
          <AutoAwesome style={{ marginRight: "8px" }} /> {t("Start")}{" "}
          {t("Risk analysis").toLowerCase()}
        </LoadingButton>
      )}

      <AnalysisConfirmationDialog
        onConfirm={handleAnalysis}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        documents={[document]}
      />
    </Box>
  );
}

export default SidebarRiskAnalysis;
