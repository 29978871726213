import styled from "@emotion/styled";
import {
  Box,
  Button,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Card as MuiCard,
  Typography as MuiTypography,
  TypographyProps,
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import useBotChat from "../../hooks/useBotChat";
import { ChevronRight } from "react-feather";
import { IPrompt } from "../../types/prompt";
import {
  epochToDate,
  useGetPromptsQuery,
  useLazyGetAssistantThreadsQuery,
} from "../../redux/slices/indexApiSlice";
import ChatInputWidget from "./ChatInputWidget";
import { AssistantThread, TabType } from "../../types/chatBot";
import DefaultPrompts from "./DefaultPrompts";
import { convertCreatedDate } from "../../utils/string";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 10px;
  // background-color: ${(props) => props.theme.palette.neutral.primary};
  // background-color: ${(props) => props.theme.palette.common.white};
  // background: ${(props) => props.theme.palette.primary.main};
  // background: ${(props) => props.theme.palette.primary.gradient};
  // background-image: url("/static/img/backgrounds/dark-raster.png");
  // background-position: top center;
  // background-size: initial;
`;
const TypographyTitle = styled(MuiTypography)`
  // color: ${(props) => props.theme.palette.common.white};
`;
const NewChatBoxButton = styled(Button)`
  height: 60px;
`;

// Main Component
type Props = {
  onConversationClick: (thread: AssistantThread) => any;
  onCreateChatBox: () => any;
  setTab: (tab: TabType) => void;
};

const ChatHome: React.FC<Props> = ({ onConversationClick, setTab }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();
  const [promptsToShow, setPromptsToShow] = useState<number>(3);
  const [promptsSearchText, setPromptsSearchText] = useState<string>("");
  const [promptsFiltered, setPromptsFiltered] = useState<IPrompt[]>([]);

  const [recentConversations, setRecentConversations] = useState<
    AssistantThread[]
  >([]);

  const [getChatHistory, { data: conversations, isSuccess, isLoading }] =
    useLazyGetAssistantThreadsQuery();

  const {
    botName,
    setOpen: setShowBotChat,
    addedMessage,
    setAddedMessage,
  } = useBotChat();

  useEffect(() => {
    getChatHistory();
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setRecentConversations(conversations?.slice(0, 3) || []);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (promptsSearchText === "") {
      setPromptsFiltered(prompts);
      setPromptsToShow(3);
    } else {
      const filteredPrompts = prompts.filter(
        (prompt: IPrompt) =>
          prompt.body
            ?.toLowerCase()
            .includes(promptsSearchText.toLowerCase()) ||
          prompt.title?.toLowerCase().includes(promptsSearchText.toLowerCase())
      );

      setPromptsFiltered(filteredPrompts);
    }
  }, [promptsSearchText, promptsLoaded, prompts]);

  const sendPromptMessage = (prompt: IPrompt) => {
    // send this prompt to the AI service

    setAddedMessage(prompt.body);
  };

  return (
    <Wrapper>
      {/* <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          height: "260px",
          width: "100%",
          opacity: "1",
          zIndex: "0",
          background:
            "linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
          pointerEvents: "none",
        }}
      /> */}
      <Box mx={8} pt={4}>
        <TypographyTitle gutterBottom variant="h4">
          {t("Hi")} {user.displayName},
        </TypographyTitle>
        <TypographyTitle variant="h6">
          {t("Welcome message body", { botName })}
          {t("")}
        </TypographyTitle>
      </Box>

      <Box mx={8}>
        <DefaultPrompts
          onPromptClick={sendPromptMessage}
          view="listWithSearch"
        />
      </Box>

      <Box mx={8}>
        <Card my={4} sx={{ boxShadow: 1, borderRadius: 3 }} variant="outlined">
          <CardContent
            sx={{
              px: 2, // remove the padding from the last child
              "&:last-child": {
                paddingBottom: 0,
              },
              py: 0,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500 }}
              gutterBottom
              pt={2}
              pl={2}
            >
              {t("Chat with", { botName })}
            </Typography>

            <ChatInputWidget />
          </CardContent>
        </Card>
      </Box>

      <Box mx={8}>
        <Card my={4} sx={{ boxShadow: 1, borderRadius: 3 }} variant="outlined">
          <CardContent
            sx={{
              px: 2, // remove the padding from the last child
              "&:last-child": {
                paddingBottom: 0,
              },
              py: 0,
            }}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 500 }}
              gutterBottom
              pt={2}
              pl={2}
            >
              {t("Recent conversations")}
            </Typography>
            {recentConversations && recentConversations?.length > 0 && (
              <List disablePadding sx={{ pb: 2 }}>
                {recentConversations?.map((item) => (
                  <ListItem
                    key={item?.id}
                    secondaryAction={<ChevronRight />}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => onConversationClick(item)}
                      dense
                      sx={{ borderRadius: 2 }}
                    >
                      {/* <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon> */}
                      {item.messages && item.messages?.length > 0 && (
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // allow 3 lines
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {item.messages[0].content[0].text.value || ""}
                            </Typography>
                          }
                          secondary={
                            item?.messages &&
                            item.messages?.length > 0 &&
                            item.messages[0].created_at && (
                              <Typography variant="caption">
                                {convertCreatedDate(
                                  epochToDate(item.messages[0].created_at)
                                )}
                              </Typography>
                            )
                          }
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box mx={8} pb={2}>
        {/* <Link>{t("Former conversations")}</Link> */}
        <Button color="primary" onClick={() => setTab(TabType.history)}>
          {t("Current conversations")}
        </Button>
      </Box>

      {/* <Box p={12}>
        <strong className="no-cursor">
          <NewChatBoxButton
            variant="contained"
            size="large"
            fullWidth
            sx={{
              boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              cursor: "pointer",
            }}
            onClick={onCreateChatBox}
          >
            <Grid container>
              <Grid md={10}>
                <Typography variant="h5">{t("Initiate chat")}</Typography>
                <Typography variant="subtitle2">
                  {/* {t("We typically reply in under minutes", { time: 10 })} *
                  {t("Initiate chat subtitle")}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SendRounded />
              </Grid>
            </Grid>
          </NewChatBoxButton>
        </strong>
      </Box> */}
    </Wrapper>
  );
};

export default ChatHome;
