import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useGetContractsInProgressQuery } from "../redux/slices/indexApiSlice";
import { ContractProgress, IContract } from "../types/contract";

const initialState: {
  openedContract: IContract | null;
  analyzedContracts: ContractProgress[];
  setOpenedContract: (c: IContract | null) => any;
  analyzingNumber: number | null | undefined;
  triggerUpdate: () => any;
  isAnalyzing?: boolean;
  analyzingSuccess?: boolean;
} = {
  openedContract: null,
  analyzedContracts: [],
  setOpenedContract: (c: IContract | null) => {},
  analyzingNumber: 0,
  isAnalyzing: false,
  analyzingSuccess: false,
  triggerUpdate: () => {},
};

const ContractContext = React.createContext(initialState);

type ContractProvider = {
  children: React.ReactNode;
};

function ContractProvider({ children }: ContractProvider) {
  const { user, isAuthenticated } = useAuth();

  const {
    data: contractsInProgress,
    isSuccess: contractsLoaded,
    refetch,
  } = useGetContractsInProgressQuery();

  const [openedContract, _setOpenedContract] = useState<IContract | null>(
    initialState.openedContract
  );

  const [analyzedContracts, setAnalyzedContracts] = useState<
    ContractProgress[]
  >(initialState.analyzedContracts);
  const [analyzingNumber, setAnalyzingNumber] = useState<number | null>();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analyzingSuccess, setAnalyzingSuccess] = useState(false);
  const [intervals, setIntervals] = useState<any[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAnalyzing && analyzingNumber! > 0) {
      setIsAnalyzing(true);
    }

    if (isAnalyzing && analyzingNumber === 0) {
      setIsAnalyzing(false);
      setAnalyzingSuccess(true);
    }
  }, [analyzingNumber, isAnalyzing]);

  useEffect(() => {
    const newAnalyzingNumber = getAnalyzingNumber();
    if (newAnalyzingNumber !== analyzingNumber) {
      setAnalyzingNumber(newAnalyzingNumber);
    }

    if (contractsInProgress && contractsInProgress.length > 0) {
      setAnalyzedContracts(contractsInProgress);

      // only start the interval if there are contracts in progress
      const processing = contractsInProgress.filter((c) => c.progress < 100);

      if (processing?.length > 0 && intervals.length === 0) {
        // start an interval to keep polling for the analysis status
        startInterval();
      }
    } else {
      setAnalyzedContracts([]);

      if (intervals.length > 0) {
        intervals.forEach((interval) => {
          clearInterval(interval);
        });
      }
    }
  }, [contractsInProgress, contractsLoaded]);

  const startInterval = () => {
    // start the interval for all the contracts
    if (intervals.length === 0) {
      const newInterval = setInterval(
        () => {
          refetch();
        },
        20000,
        90 // run every 20 seconds, for 90 times. This is 30 minutes max.
      );

      setIntervals([newInterval]);
    }
  };

  const triggerUpdate = async () => {
    // When a contract is created, we need to update the list of contracts immediately instead of waiting for the interval
    await refetch();
  };

  const getAnalyzingNumber = (): number => {
    return contractsInProgress?.length || 0;
  };

  const setOpenedContract = (c: IContract | null) => {
    _setOpenedContract(c);
  };

  return (
    <ContractContext.Provider
      value={{
        openedContract,
        analyzedContracts,
        setOpenedContract,
        analyzingNumber,
        isAnalyzing,
        analyzingSuccess,
        triggerUpdate,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}

export { ContractContext, ContractProvider };
