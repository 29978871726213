import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  Fade,
  Box,
  Typography,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel,
  Check,
  Close,
  AlarmAddOutlined,
  QueryBuilder,
  QueryStats,
  QuestionAnswer,
  AddCircle,
  AddCircleOutlined,
  AddCircleOutline,
  Message,
  AddComment,
} from "@mui/icons-material";

import { IContractCategory } from "../../types/contractCategory";
import useAuth from "../../hooks/useAuth";
import {
  useAddContractCategoryMutation,
  useDeleteContractCategoryMutation,
  useUpdateContractCategoryMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { MessageSquare } from "react-feather";
import ContractCategoryForm from "../forms/ContractCategoryForm";

const Button = styled(MuiButton)(spacing);

interface IContractCategoryDialogProps {
  mode: DialogMode;
  open?: boolean;
  handleClose?: () => void;
  contractCategory?: IContractCategory;
  disabled?: boolean;
  compact?: boolean;
  inlineConfirmation?: boolean;
  iconOnly?: boolean;
  asMenuItem?: boolean; // renders the button as a menu item
  buttonVariant?: "text" | "contained" | "outlined";
}

function ContractCategoryDialog({ ...props }: IContractCategoryDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [open, setOpen] = useState(props.open || false);
  const { mode, asMenuItem } = props;

  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [
    addContractCategory,
    {
      data: addedContractCategory,
      isLoading: isAdding,
      isSuccess: isAdded,
      reset: resetAdd,
      isError: addContractCategoryError,
      error: addContractCategoryErrorObject,
    },
  ] = useAddContractCategoryMutation();

  const [
    updateContractCategory,
    {
      data: updatedContractCategory,
      isSuccess: isUpdated,
      reset: resetUpdate,
      isError: updateContractCategoryError,
      error: updateContractCategoryErrorObject,
    },
  ] = useUpdateContractCategoryMutation();

  const [
    deleteContractCategory,
    {
      data: deletedContractCategory,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
      isError: deleteContractCategoryError,
      error: deleteContractCategoryErrorObject,
    },
  ] = useDeleteContractCategoryMutation();

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      if (isAdded && addedContractCategory) {
        resetAdd();
      }

      if (isUpdated && updatedContractCategory) {
        resetUpdate();
      }

      if (isDeleted && deletedContractCategory) {
        resetDelete();
      }

      setSuccess(true);

      resetAndClose();
    }
  }, [
    isAdded,
    isUpdated,
    isDeleted,
    addedContractCategory,
    updatedContractCategory,
    deletedContractCategory,
  ]);

  const resetAndClose = () => {
    setOpen(false);

    if (props.handleClose) {
      props.handleClose();
    }

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleAddContractCategory = async (
    contractCategory: IContractCategory
  ) => {
    const contractCategoryToAdd: IContractCategory = {
      ...contractCategory,
      author: user?.id,
    };

    await addContractCategory(contractCategoryToAdd);
  };

  const handleUpdateContractCategory = async (
    contractCategory: IContractCategory
  ) => {
    const contractCategoryToUpdate: IContractCategory = { ...contractCategory };

    await updateContractCategory(contractCategoryToUpdate);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.contractCategory?.id) {
      await deleteContractCategory(props.contractCategory.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete contract category")}>
              {props.iconOnly ? (
                <IconButton
                  size="small"
                  onClick={handleDeleteClick}
                  disabled={props.disabled}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setOpen(true)}
                  disabled={props.disabled}
                >
                  {t("Delete contract category")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                  disabled={props.disabled}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                  disabled={props.disabled}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {props.mode === DialogMode.Add && (
        <>
          {props.iconOnly ? (
            <IconButton
              size="large"
              color="inherit"
              onClick={() => setOpen(true)}
            >
              <Message />
            </IconButton>
          ) : (
            <Button
              variant={props.buttonVariant || "text"}
              onClick={() => setOpen(true)}
              disabled={props.disabled}
            >
              <AddCircleOutline sx={{ mr: 2 }} /> {t("Add contract category")}
            </Button>
          )}
        </>
      )}

      {mode === DialogMode.Edit && (
        <Tooltip title={t("Edit contract category")}>
          {props.iconOnly ? (
            <IconButton
              size="small"
              onClick={handleEditClick}
              disabled={props.disabled}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <Button
              color="primary"
              onClick={handleEditClick}
              disabled={props.disabled}
            >
              {t("Edit")}
            </Button>
          )}
        </Tooltip>
      )}

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        // onClose={() => setOpen(false)}
        aria-labelledby="contract-category-dialog-title"
        id="contract-category-dialog"
      >
        <DialogTitle id="contract-category-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid>
              {mode === DialogMode.Add
                ? t("Add contract category")
                : mode === DialogMode.Edit
                ? t("Edit contract category")
                : mode === DialogMode.Delete
                ? t("Are you sure you want to delete this contract category?")
                : ""}
            </Grid>
            <Grid>
              <IconButton size="small" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        {mode !== DialogMode.Delete && (
          <DialogContent>
            {(updateContractCategoryError ||
              addContractCategoryError ||
              deleteContractCategoryError) && (
              <Alert severity="error" sx={{ mb: 6 }}>
                <AlertTitle>{t("An error occurred!")}</AlertTitle>

                {JSON.stringify(addContractCategoryErrorObject)}

                {JSON.stringify(updateContractCategoryErrorObject)}

                {JSON.stringify(deleteContractCategoryErrorObject)}
              </Alert>
            )}

            <ContractCategoryForm
              mode={props.mode}
              addContractCategory={handleAddContractCategory}
              updateContractCategory={handleUpdateContractCategory}
              contractCategory={props.contractCategory}
            />
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button
              variant="contained"
              onClick={() => resetAndClose()}
              color="primary"
            >
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button onClick={() => resetAndClose()} color="primary">
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="contract-category-form"
                  color="primary"
                  disabled={isAdding}
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContractCategoryDialog;
