// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {
  ContractProgress,
  ContractStatus,
  IContract,
} from "../../types/contract";
import { ISharePointConnection } from "../../types/sharepointconnection";
import { IDocument } from "../../types/document";
import { IMessage } from "../../types/message";
import { INote } from "../../types/note";
import { IUser } from "../../types/user";
import { IKPI } from "../../types/kpi";
import { IActivity } from "../../types/activity";
import { INotification } from "../../types/notification";
import {
  ITrustedPartnerInvite,
  ITrustedPartnerInviteResponse,
  ITrustedPartnerTrust,
} from "../../types/trustedpartner";
import { IAIResponse } from "../../types/airesponse";
import { IPrompt } from "../../types/prompt";
import { ISettings } from "@fluentui/react";
import { AssistantMessage, AssistantThread } from "../../types/chatBot";
import { IQuestion } from "../../../../server/types/question";
import i18next from "i18next";
import { IConnectedDrive } from "../../types/connecteddrive";
import { getAccessTokenForTenant } from "./authSlice";
import { ISignature } from "../../types/signature";
import { ISubscriptionInfo } from "../../types/subscriptionInfo";
import { IFolder } from "../../types/folder";
import { ContractExtractionResult } from "../../types/aiOutput";
import { HumanDataVerification } from "../../types/humanDataVerification";
import { IContractCategory } from "../../types/contractCategory";

export function epochToDate(ts: number): string {
  if (ts.toString().length === 13) {
    return new Date(ts)?.toISOString();
  }

  return new Date(ts * 1000)?.toISOString();
}

function getDeltas(items: IActivity[]) {
  items.forEach((item: any, index: number) => {
    if (!item?.payload?.body) {
      item.delta = [];

      return;
    }

    const currentItemProps = item.payload.body;
    const currentItemContractId =
      item.payload.body?.id || item.payload.locals?.id;

    if (!currentItemContractId) {
      item.delta = [];

      return;
    }

    // find the previous item based on the contract id of the payload
    const previousItemIndexes: number[] = [];
    items.forEach((act: IActivity, i: number) => {
      if (
        i < index &&
        (act?.payload?.body?.id === currentItemContractId ||
          act?.payload?.locals?.id === currentItemContractId)
      ) {
        previousItemIndexes.push(i);
      }
    });

    // select the max index
    const previousItemIndex =
      previousItemIndexes.length > 0 ? Math.max(...previousItemIndexes) : -1;

    if (previousItemIndex === -1) {
      item.delta = [];
      return;
    }

    const previousItemProps = items[previousItemIndex].payload.body;

    // get the differences between both props
    const changedProps = Object.keys(currentItemProps).filter((key: string) => {
      if (!previousItemProps.hasOwnProperty(key)) {
        // the current item updated a property that did not exist in the previous item
        return true;
      }

      if (key === "signatures") {
        const currentSig = currentItemProps[key].map(
          (sig: ISignature, i: number) => {
            return {
              email: sig.email,
              isSigned: sig.isSigned,
            };
          }
        );

        const previousSig = previousItemProps[key].map(
          (sig: ISignature, i: number) => {
            return {
              email: sig.email,
              isSigned: sig.isSigned,
            };
          }
        );

        if (JSON.stringify(currentSig) !== JSON.stringify(previousSig)) {
          return true;
        }
      } else if (key === "ownerIds" || key === "managerIds") {
        if (
          JSON.stringify(currentItemProps[key]) !==
          JSON.stringify(previousItemProps[key])
        ) {
          return true;
        }
      } else {
        if (currentItemProps[key] !== previousItemProps[key]) {
          // the prop exists in both the current and previous items, only include it if the value has changed
          return currentItemProps[key] !== previousItemProps[key];
        }
      }

      return false;
    });

    const delta: any = [];

    changedProps.forEach((prop: string) => {
      delta[prop] = currentItemProps[prop];
    });

    item.delta = delta;
  });

  return items;
}

export const AzureApi = createApi({
  reducerPath: "AzureApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState, endpoint }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      // get the clientId part from the url
      const clientId = window.location.pathname.match(
        /contracts-under-management\/([^\/]+)/
      )?.[1];

      const useTrustedPartnerToken =
        clientId &&
        (endpoint == "getContracts" ||
          endpoint === "getContract" ||
          endpoint === "getDocuments" ||
          endpoint === "getDocument");

      if (useTrustedPartnerToken) {
        const accessTokenForTenant = getAccessTokenForTenant(
          getState(),
          clientId
        );

        if (!accessTokenForTenant) {
          // throw new Error("No access token for client available");
        } else {
          headers.set("Authorization", `Bearer ${accessTokenForTenant}`);
        }
      } else {
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
          if (
            endpoint != "getContractAISignature" &&
            endpoint != "addContractAISignature" &&
            endpoint != "getSignatureDocumentContent" &&
            endpoint != "addSignatureDocumentContent" &&
            endpoint != "getReSignContract" &&
            endpoint != "getDocumentContent"
          ) {
            throw new Error("No access token available");
          }
        }
      }

      return headers;
    },
  }),
  tagTypes: [
    "Contract",
    "Document",
    "Vendor",
    "Message",
    "Note",
    "Feedback",
    "Activity",
    "Prompt",
    "SharePointConnection",
    "ConnectedDrive",
    "Folder",
    "Settings",
    "HumanDataVerification",
    "SubscriptionInfo",
    "ContractCategory",
    "AssistantThread",
    "TermsAndConditions",
    "AIOutput",
  ],

  endpoints: (builder) => ({
    getContracts: builder.query<IContract[], ContractStatus>({
      query: (status) => ({
        url: `/contracts${status ? `?status=${status}` : ""}`,
        method: "GET",
      }),
      // providesTags: ["Contract"],
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "Contract", id }))
          : ["Contract"],
      transformResponse: (response: any): IContract[] => {
        const sortedContracts = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items: IContract[] = sortedContracts.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getContract: builder.query<IContract, string>({
      query: (id) => ({
        url: `/contracts/${id}`,
        method: "GET",
      }),
      // providesTags: ["Contract"],
      providesTags: (result, error, arg) => [{ type: "Contract", id: arg }],
      transformResponse: (response: any) => {
        const item = response?.data;
        const signatures = item.signatures;

        return {
          ...item,
          signatures,
          startDate: item?.startDate,
          endDate: item?.endDate,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    getClientsUnderManagement: builder.query<any[], void>({
      query: () => ({
        url: "/clients-under-management",
        method: "GET",
      }),
      transformResponse: (response: any): IContract[] => {
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getContractsUnderManagement: builder.query<IContract[], string>({
      query: (tenantId) => ({
        url: `/clients-under-management/${tenantId}/contracts`,
        method: "GET",
      }),
      providesTags: ["Contract"],
      transformResponse: (response: any): IContract[] => {
        const sortedContracts = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedContracts.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getManagedContract: builder.query<IContract, IContract>({
      query: ({ id }) => ({
        url: `/managed-contracts/${id}`,
        method: "GET",
      }),
      providesTags: ["Contract"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),

    getTopVendors: builder.query<any, void>({
      query: () => ({
        url: `/stats/topvendors`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getContractExpirationCount: builder.query<any, any>({
      query: ({ months }) => ({
        url: `/stats/contractexpirations/${months}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addDocument: builder.mutation<IDocument, IDocument>({
      query: (document) => ({
        url: `/contracts/${document.contractId}/documents`,
        method: "POST",
        body: document,
      }),
      invalidatesTags: ["Document"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    getDocuments: builder.query<IDocument[], string>({
      query: (id) => ({
        url: `/contracts/${id}/documents`,
        method: "GET",
      }),
      providesTags: ["Document"],
      transformResponse: (response: any) => {
        // Sort the items by their 'createdDate' property in ascending order
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems?.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items;
      },
    }),
    getAttachments: builder.query<IDocument[], IContract>({
      query: ({ id }) => ({
        url: `/contracts/${id}/documents?type=attachment`,
        method: "GET",
      }),
      providesTags: ["Document"],
      transformResponse: (response: any) => {
        // Sort the items by their 'createdDate' property in ascending order
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems?.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getDocument: builder.query<IDocument, IDocument>({
      query: (document) => ({
        url: `/contracts/${document.contractId}/documents/${document.id}`,
        method: "GET",
      }),
      providesTags: ["Document"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    deleteDocument: builder.mutation<IDocument, IDocument>({
      query: (document) => ({
        url: `/contracts/${document.contractId}/documents/${document.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Document"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getDocumentContent: builder.query<
      { data: ArrayBuffer; type: string },
      IDocument
    >({
      //only start if enabled is true
      query: ({ id }) => ({
        url: `/documents/${id}/content`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addDocumentContent: builder.mutation({
      query: ({ contractId, documentId, file }) => ({
        url: `/contracts/${contractId}/documents/${documentId}/upload`,
        method: "PUT",
        body: file,
      }),
    }),
    addDocumentContentFromDownloadUrl: builder.mutation<
      any,
      IDocument & { downloadUrl: string }
    >({
      query: ({ contractId, id, name, downloadUrl }) => ({
        url: `/contracts/${contractId}/documents/${id}/upload/downloadUrl`,
        method: "POST",
        body: {
          downloadUrl: downloadUrl,
          filename: name,
        },
      }),
    }),
    deleteContract: builder.mutation<any, string>({
      query: (id) => ({
        url: `/contracts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contract"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    syncContractsFromSharePoint: builder.mutation<IContract[], IContract[]>({
      query: (contracts) => ({
        url: `/contracts/sync`,
        method: "POST",
        body: contracts,
      }),
    }),
    syncConnectedDrive: builder.mutation<IDocument[], any>({
      query: ({ driveItems, connectedDrive }) => ({
        url: `/connected-drives/${connectedDrive.id}/sync`,
        method: "POST",
        body: driveItems,
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addContract: builder.mutation<IContract, IContract>({
      query: (metadata) => ({
        url: "/contracts",
        method: "POST",
        body: metadata,
      }),
      invalidatesTags: ["Contract", "Activity", "SubscriptionInfo"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    updateContract: builder.mutation<
      IContract,
      Partial<IContract> & Pick<IContract, "id">
    >({
      query: (contract) => ({
        url: `/contracts/${contract.id}`,
        method: "PUT",
        body: contract,
      }),
      // providesTags: ["Activity"],
      invalidatesTags: (result, error, arg) => [
        { type: "Contract", id: arg.id },
      ],
      // invalidatesTags: ["Contract"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    // getFeedbackList: builder.query<IFeedback, IFeedback>({
    //   query: () => ({
    //     url: `/feedback`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response: any) => {
    //     const items = response?.data;

    //     return items?.map((item: any) => {
    //       return {
    //         ...item,
    //         created: item._tsc,
    //         modified:item._ts,
    //       };
    //     });
    //   },
    // }),
    // getFeedback: builder.query<IFeedback[], IContract>({
    //   query: ({ id }) => ({
    //     url: `/contracts/${id}/feedback`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response: any) => {
    //     const items = response?.data;

    //     return items?.map((item: any) => {
    //       return {
    //         ...item,
    //         created: item._tsc,
    //         modified:item._ts,
    //       };
    //     });
    //   },
    // }),
    // addFeedback: builder.mutation<IFeedback, IFeedback>({
    //   query: (feedback) => ({
    //     url: `/contracts/${feedback.contractId}/feedback`,
    //     method: "POST",
    //     body: feedback,
    //   }),
    //   transformResponse: (response: any) => {
    //     const item = response?.data;

    //     return {
    //       ...item,
    //       created: item._tsc,
    //       modified:item._ts,
    //     };
    //   },
    // }),
    // updateFeedback: builder.mutation<IFeedback, IFeedback>({
    //   query: (feedback) => ({
    //     url: `/contracts/${feedback.contractId}/feedback/${feedback.id}}`,
    //     method: "PUT",
    //     body: feedback,
    //   }),
    //   transformResponse: (response: any) => {
    //     const item = response?.data;

    //     return {
    //       ...item,
    //       created: item._tsc,
    //       modified:item._ts,
    //     };
    //   },
    // }),
    // deleteFeedback: builder.mutation<IFeedback, IFeedback>({
    //   query: ({ contractId, id }) => ({
    //     url: `/contracts/${contractId}/feedback/${id}`,
    //     method: "DELETE",
    //   }),
    //   transformResponse: (response: any) => {
    //     const item = response?.data;

    //     return {
    //       ...item,
    //       created: item._tsc,
    //       modified:item._ts,
    //     };
    //   },
    // }),
    getSharePointConnections: builder.query<ISharePointConnection[], void>({
      query: () => ({
        url: `/connections/sharepoint`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "SharePointConnection", id }))
          : ["SharePointConnection"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addSharePointConnection: builder.mutation<
      ISharePointConnection,
      ISharePointConnection
    >({
      query: (connection) => ({
        url: "/connections/sharepoint",
        method: "POST",
        body: connection,
      }),
      invalidatesTags: ["SharePointConnection"],
    }),
    deleteSharePointConnection: builder.mutation<ISharePointConnection, string>(
      {
        query: (id) => ({
          url: `/connections/sharepoint/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, id) => [
          { type: "SharePointConnection", id },
        ],
      }
    ),
    addConnectedDrive: builder.mutation<IConnectedDrive, IConnectedDrive>({
      query: (connection) => ({
        url: "/connected-drives",
        method: "POST",
        body: connection,
      }),
      invalidatesTags: ["ConnectedDrive"],
    }),
    getConnectedDrives: builder.query<IConnectedDrive[], void>({
      query: () => ({
        url: "/connected-drives",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "ConnectedDrive", id }))
          : ["ConnectedDrive"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    deleteConnectedDrive: builder.mutation<IConnectedDrive, string>({
      query: (id) => ({
        url: `/connected-drives/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "ConnectedDrive", id }],
    }),
    getMessages: builder.query<IMessage[], void>({
      query: () => ({
        url: `/messages`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedMessages = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        return sortedMessages.reverse().map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });
      },
    }),
    getNotes: builder.query<INote[], boolean>({
      query: (withReminderDate) => ({
        url: `/notes${withReminderDate ? "?withReminderDate=true" : ""}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedNotes = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedNotes.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Note", id })) : ["Note"],
    }),
    getMyNotes: builder.query<INote[], boolean>({
      query: (withReminderDate) => ({
        url: `/my-notes${withReminderDate ? "?withReminderDate=true" : ""}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedNotes = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedNotes.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Note", id })) : ["Note"],
    }),
    getNotesForContract: builder.query<INote[], string>({
      query: (id) => ({
        url: `/contracts/${id}/notes`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedNotes = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedNotes.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Note", id })) : ["Note"],
    }),
    getMessage: builder.query<IMessage, IMessage>({
      query: ({ id }) => ({
        url: `/messages/${id}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        const item = { ...response?.data };
        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    getMessageReplies: builder.query<IMessage, IMessage>({
      query: ({ id }) => ({
        url: `/messages/${id}/replies`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their created date in descending order
        const sortedMessages = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedMessages.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    deleteMessage: builder.mutation<any, IMessage>({
      query: ({ contractId, id }) => ({
        url: `/contracts/${contractId}/messages/${id}`,
        method: "DELETE",
      }),
    }),
    addMessage: builder.mutation<any, IMessage>({
      query: (message) => ({
        url: `/contracts/${message.contractId}/messages`,
        method: "POST",
        body: message,
      }),
    }),
    updateMessage: builder.mutation<IMessage, IMessage>({
      query: (message) => ({
        url: `/contracts/${message.contractId}/messages/${message.id}}`,
        method: "PUT",
        body: message,
      }),
    }),

    deleteNote: builder.mutation<string, INote>({
      query: ({ contractId, id }) => ({
        url: `/contracts/${contractId}/notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Note"],
    }),
    addNote: builder.mutation<any, INote>({
      query: (note) => ({
        url: `/contracts/${note.contractId}/notes`,
        method: "POST",
        body: note,
      }),
      invalidatesTags: ["Note"],
    }),
    updateNote: builder.mutation<INote, INote>({
      query: (note) => ({
        url: `/contracts/${note.contractId}/notes/${note.id}}`,
        method: "PUT",
        body: note,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Note", id: arg.id }],
    }),

    getMessagesFeedForUser: builder.query<IMessage[], IUser>({
      query: ({ id }) => ({
        url: `/users/${id}/messagesfeed`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the messages by their 'createdDate' property in ascending order
        const sortedMessages = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedMessages.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),

    getLibraryKPIs: builder.query<IKPI[], IKPI>({
      query: () => ({
        url: `/kpis`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getLibraryKPI: builder.query<IKPI[], IKPI>({
      query: ({ id }) => ({
        url: `/kpis/${id}`,
        method: "GET",
      }),
    }),
    deleteLibraryKPI: builder.mutation<any, string>({
      query: (id) => ({
        url: `/kpis/${id}`,
        method: "DELETE",
      }),
    }),
    addLibraryKPI: builder.mutation<any, IKPI>({
      query: (kpi) => ({
        url: `/kpis`,
        method: "POST",
        body: kpi,
      }),
    }),
    updateLibraryKPI: builder.mutation<IKPI, IKPI>({
      query: (kpi) => ({
        url: `/kpis/${kpi.id}`,
        method: "PUT",
        body: kpi,
      }),
    }),

    // getTrustedPartners: builder.query<ITrustedPartner[], any>({
    //   query: () => ({
    //     url: `/admin/trusted-partners`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response: any) => {
    //     // Sort the activities by their 'createdDate' property in ascending order
    //     const sortedItems = response?.data?.sort((a: any, b: any) => {
    //       if (!a._tsc || !b._tsc) return 0;

    //       return a._tsc - b._tsc;
    //     });

    //     const items = sortedItems.map((item: any) => {
    //       return {
    //         ...item,
    //         created: item._tsc,
    //         modified:item._ts,
    //       };
    //     });

    //     return items.reverse();
    //   },
    // }),
    getTrustedPartnerInvites: builder.query<ITrustedPartnerInvite[], void>({
      query: () => ({
        url: `/admin/trusted-partners/invites`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the activities by their 'createdDate' property in ascending order
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    getTrustedPartnerInviteResponses: builder.query<
      ITrustedPartnerInviteResponse[],
      string
    >({
      query: (id) => ({
        url: `/admin/trusted-partners/invites/${id}/responses`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        // Sort the activities by their 'createdDate' property in ascending order
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items.reverse();
      },
    }),
    // getTrustedPartnerInvitesAccepted: builder.query<ITrustedPartnerInviteAccepted[], any>({
    //   query: () => ({
    //     url: `/admin/trusted-partners/invites/accepted`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response: any) => {
    //     // Sort the activities by their 'createdDate' property in ascending order
    //     const sortedItems = response?.data?.sort((a: any, b: any) => {
    //       if (!a._tsc || !b._tsc) return 0;

    //       return a._tsc - b._tsc;
    //     });

    //     const items = sortedItems.map((item: any) => {
    //       return { ...item, created: item._tsc, modified:item._ts };
    //     });

    //     return items.reverse();
    //   },
    // }),
    getAccessTokenForClient: builder.query<any, string>({
      query: (customerTenantId) => ({
        url: `/admin/trusted-partners/${customerTenantId}/accessToken`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getTrustedPartnerTrusts: builder.query<ITrustedPartnerTrust[], void>({
      query: () => ({
        url: `/admin/trusted-partners`,
        method: "GET",
      }),
      transformResponse: (response: any) => response?.data,
    }),
    addTrustedPartnerInvite: builder.mutation<
      ITrustedPartnerInvite,
      ITrustedPartnerInvite
    >({
      query: (invite: ITrustedPartnerInvite) => ({
        url: `/admin/trusted-partners/${invite.partnerId}/invite`,
        method: "POST",
        body: invite,
      }),
    }),
    addTrustedPartnerInviteAccepted: builder.mutation<
      ITrustedPartnerInviteResponse,
      ITrustedPartnerInvite
    >({
      query: (invite) => ({
        url: `/admin/trusted-partners/${invite.tenantId}/invites/${invite.id}/accept`,
        method: "POST",
      }),
    }),
    addTrustedPartnerInviteDeclined: builder.mutation<
      ITrustedPartnerInviteResponse,
      ITrustedPartnerInvite
    >({
      query: (invite) => ({
        url: `/admin/trusted-partners/${invite.tenantId}/invites/${invite.id}/decline`,
        method: "POST",
      }),
    }),
    getTrustedPartnerAcceptedInvites: builder.query<any, void>({
      query: () => ({
        url: `/admin/trusted-partners/invites/accepted`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    deleteTrustedPartnerInviteItem: builder.mutation<any, string>({
      query: (id) => ({
        url: `/admin/trusted-partners/invites/${id}`,
        method: "DELETE",
      }),
    }),
    // getFeedbackCategories: builder.query({
    //   query: () => ({
    //     url: `/settings/feedback-categories`,
    //     method: "GET",
    //   }),
    //   transformResponse: (response: any) => {
    //     return response?.data?.value;
    //   },
    // }),
    getActivities: builder.query<IActivity[], void>({
      query: () => ({
        url: `/admin/activitylog`,
        method: "GET",
      }),
      providesTags: ["Activity"],
      transformResponse: (response: any) => {
        // Sort the activities by their 'createdDate' property in ascending order
        const sortedActivities = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedActivities.map((item: any) => {
          return {
            ...item,
            contractName:
              item?.entity === "contract" ? item?.payload?.body?.name : "",
            vendorName:
              item?.entity === "vendor" ? item?.payload?.body?.name : "",
            created: item._tsc,
            modified: item._ts,
          };
        });

        // for each payload, add a property for the delta
        const itemsWithDelta = getDeltas(items);

        return itemsWithDelta.reverse();
      },
    }),
    getActivitiesForContract: builder.query<IActivity[], string>({
      query: (id) => ({
        url: `/contracts/${id}/activities`,
        method: "GET",
      }),
      providesTags: ["Activity"],
      transformResponse: (response: any) => {
        // Sort the activities by their 'createdDate' property in ascending order
        const sortedActivities = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedActivities.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        // for each payload, add a property for the delta
        const itemsWithDelta = getDeltas(items);

        return itemsWithDelta.reverse();
      },
    }),

    getNotifications: builder.query<INotification[], void>({
      query: () => ({
        url: `/notifications`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        const notifications = response?.data.map((res: any) => {
          const notification: INotification = {
            id: res.id,
            text: res.title,
            readBy: res.readBy,
            insertionTime: res._tsc,
            type: res.type,
            contractId: res.contractId,
            contractName: res.contractName,
            noticePeriod: res.noticePeriod,
            endDate: res.endDate,
            taggedUsers: res.taggedUsers,
          };

          return notification;
        });

        return notifications;
      },
    }),
    updateNotification: builder.mutation<INotification, INotification>({
      query: (notification) => ({
        url: `/notifications/${notification.id}`,
        method: "PUT",
        body: notification,
      }),
    }),
    deleteNotification: builder.mutation<any, string>({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: "DELETE",
      }),
    }),
    addSettings: builder.mutation<ISettings, ISettings>({
      query: (settings) => ({
        url: `/settings`,
        method: "POST",
        body: settings,
      }),
    }),
    updateSettings: builder.mutation<ISettings, ISettings>({
      query: (settings) => ({
        url: `/settings`,
        method: "PUT",
        body: settings,
      }),
    }),
    getSettings: builder.query<ISettings, void>({
      query: () => ({
        url: `/settings`,
        method: "GET",
      }),
      providesTags: ["Settings"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getWebPubSub: builder.query<any, void>({
      query: () => ({
        url: `/connect-web-pub-sub`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),

    addContractAIResponse: builder.mutation<IAIResponse, IAIResponse>({
      query: (aiResponse) => ({
        url: `/contracts/${aiResponse.contractId}/airesponses`,
        method: "POST",
        body: aiResponse,
      }),
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    deleteContractAIResponse: builder.mutation<string, IAIResponse>({
      query: (aiResponse) => ({
        url: `/contracts/${aiResponse.contractId}/airesponses/${aiResponse.id}`,
        method: "DELETE",
      }),
    }),
    getContractAIResponses: builder.query<IAIResponse[], IContract>({
      query: ({ id }) => ({
        url: `/contracts/${id}/airesponses`,
        method: "GET",
      }),
      transformResponse: (response: any): IAIResponse[] => {
        const sortedResponses = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedResponses.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items;
      },
    }),

    updateUser: builder.mutation<any, any>({
      query: (user) => ({
        url: `/store-user`,
        method: "PUT",
        body: user,
      }),
    }),

    getAssistantThread: builder.query<AssistantThread, string>({
      query: (id) => ({
        url: `/assistant/threads/${id}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [{ type: "AssistantThread", id: result.id }]
          : ["AssistantThread"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getAssistantThreads: builder.query<AssistantThread[], void>({
      query: () => ({
        url: `/assistant/threads`,
        method: "GET",
      }),
      providesTags: ["AssistantThread"],
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    addChatBot: builder.mutation<AssistantThread, IQuestion>({
      query: (question) => {
        return {
          url: `/assistant`,
          method: "POST",
          body: { ...question, language: i18next.language },
        };
      },
      invalidatesTags: ["AssistantThread"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    updateChatBot: builder.mutation<
      AssistantMessage,
      AssistantThread & { message: string }
    >({
      query: ({ id, message, messages }) => {
        return {
          url: `/assistant/${messages[0].assistant_id}/threads/${id}`,
          method: "PUT",
          body: { message, language: i18next.language },
        };
      },
      invalidatesTags: ["AssistantThread"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getContractsByStatus: builder.query<IContract[], ContractStatus>({
      query: (status) => ({
        url: `/contracts-by-status/${status}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "Contract", id }))
          : ["Contract"],
      transformResponse: (response: any) => {
        return (
          response?.data.map((c: IContract) => ({
            document: {
              name: c.name,
              contractId: c.id,
            },
            progress: 0,
            analyzeStatus: c.analyzeStatus,
            analyzeTaskId: c.analyzeTaskId,
          })) ?? []
        );
      },
    }),
    getSigningContracts: builder.query<any, void>({
      query: () => ({
        url: `/signing-contracts`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    getMySigningTasks: builder.query<any, void>({
      query: () => ({
        url: `/contracts/my-signing-tasks`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    getContractsInProgress: builder.query<ContractProgress[], void>({
      query: () => ({
        url: `/jobs/contracts-in-progress`,
        method: "GET",
      }),
      providesTags: ["Contract", "AIOutput"],
      transformResponse: (response: any) => {
        return response?.data ?? [];
      },
    }),
    getPrompts: builder.query<IPrompt[], void>({
      query: () => ({
        url: `/prompts`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Prompt", id })) : ["Prompt"],
      transformResponse: (response: any) => {
        const sortedPrompts = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return b._tsc - a._tsc;
        });

        return sortedPrompts ?? [];
      },
    }),
    deletePrompt: builder.mutation<string, string>({
      query: (id) => ({
        url: `/prompts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Prompt", id }],
    }),
    addPrompt: builder.mutation<IPrompt, IPrompt>({
      query: (prompt) => ({
        url: `/prompts`,
        method: "POST",
        body: prompt,
      }),
      invalidatesTags: ["Prompt"],
    }),
    updatePrompt: builder.mutation<IPrompt, IPrompt>({
      query: (prompt) => ({
        url: `/prompts/${prompt.id}`,
        method: "PUT",
        body: prompt,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Prompt", id: arg.id }],
    }),
    getSubscriptionInfo: builder.query<ISubscriptionInfo, void>({
      query: () => ({
        url: `/subscription`,
        method: "GET",
      }),
      providesTags: ["SubscriptionInfo"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getFolders: builder.query<IFolder[], void>({
      query: () => ({
        url: `/folders`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Folder", id })) : ["Folder"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addFolder: builder.mutation<IFolder, IFolder>({
      query: (connection) => ({
        url: "/folders",
        method: "POST",
        body: connection,
      }),
      invalidatesTags: ["Folder"],
    }),
    updateFolder: builder.mutation<IFolder, IFolder>({
      query: (folder) => ({
        url: `/folders/${folder.id}`,
        method: "PUT",
        body: folder,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Folder", id: arg.id }],
    }),
    deleteFolder: builder.mutation<IFolder, string>({
      query: (id) => ({
        url: `/folders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Folder", id }],
    }),
    getAIOutput: builder.query<ContractExtractionResult[], IContract>({
      query: ({ id }) => ({
        url: `/contracts/${id}/ai-output`,
        method: "GET",
      }),
      providesTags: ["AIOutput"],
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    deleteAIOutput: builder.mutation<string, ContractExtractionResult>({
      query: ({ id }) => ({
        url: `/ai-outputs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "AIOutput", id }],
    }),

    // getAIOutput: builder.query<ContractExtractionResult[], IDocument>({
    //   query: (document) => ({
    //     url: `/contracts/${document.contractId}/documents/${document.id}/ai-output`,
    //     method: "GET",
    //   }),
    //   // providesTags: ["Document"],
    //   transformResponse: (response: any) => {
    //     // every prompt and the metadata for the ai output is stored separately, they can be grouped by the aiInputId
    //     // const groupedByInvocationId = groupBy(response?.data, "aiInputId");

    //     // // then create it as one object
    //     // const aiOutput = Object.keys(groupedByInvocationId).map((key) => {
    //     //   const lastObject =
    //     //     groupedByInvocationId[key][groupedByInvocationId[key].length - 1];

    //     //   const prompts = groupedByInvocationId[key]
    //     //     .filter((item) => item.prompts?.length > 0)
    //     //     .map((item) => item.prompts)
    //     //     .reduce((acc, val) => acc.concat(val), [])
    //     //     .filter((item: any) => item);

    //     //   let metadata = groupedByInvocationId[key].find((item) => {
    //     //     return item.metadata?.structuralData;
    //     //   })?.metadata;

    //     //   // if (!metadata) {
    //     //   //   // if there is no metadata, then use the last metadata object
    //     //   //   metadata = response?.data.find(
    //     //   //     (r: any) => r.metadata?.structuralData
    //     //   //   )?.metadata;
    //     //   // }

    //     //   return {
    //     //     ...lastObject,
    //     //     prompts,
    //     //     metadata,
    //     //   };
    //     // });

    //     return response.data;
    //   },
    // }),
    getHumanDataVerifications: builder.query<
      HumanDataVerification[],
      IContract
    >({
      query: ({ id }) => ({
        url: `/contracts/${id}/human-data-verifications`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "HumanDataVerification", id }))
          : ["HumanDataVerification"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    addHumanDataVerification: builder.mutation<HumanDataVerification, any>({
      query: (verification) => ({
        url: `/contracts/${verification.contractId}/human-data-verifications`,
        method: "POST",
        body: verification,
      }),
      invalidatesTags: ["HumanDataVerification"],
    }),
    updateHumanDataVerification: builder.mutation<
      HumanDataVerification,
      HumanDataVerification
    >({
      query: (verification) => ({
        url: `/contracts/${verification.contractId}/human-data-verifications/${verification.id}`,
        method: "PUT",
        body: verification,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "HumanDataVerification", id: arg.id },
      ],
    }),
    deleteHumanDataVerification: builder.mutation<
      string,
      HumanDataVerification
    >({
      query: ({ contractId, id }) => ({
        url: `/contracts/${contractId}/human-data-verifications/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "HumanDataVerification", id },
      ],
    }),

    getContractCategories: builder.query<IContractCategory[], void>({
      query: () => ({
        url: `/contract-categories`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "ContractCategory", id }))
          : ["ContractCategory"],
      transformResponse: (response: any) => {
        const sorted = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return b._tsc - a._tsc;
        });

        return sorted ?? [];
      },
    }),
    deleteContractCategory: builder.mutation<string, string>({
      query: (id) => ({
        url: `/contract-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "ContractCategory", id },
      ],
    }),
    addContractCategory: builder.mutation<IContractCategory, IContractCategory>(
      {
        query: (prompt) => ({
          url: `/contract-categories`,
          method: "POST",
          body: prompt,
        }),
        invalidatesTags: ["ContractCategory"],
      }
    ),
    updateContractCategory: builder.mutation<
      IContractCategory,
      IContractCategory
    >({
      query: (item) => ({
        url: `/contract-categories/${item.id}`,
        method: "PUT",
        body: item,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ContractCategory", id: arg.id },
      ],
    }),
    getTermsAndConditionsDocument: builder.query<IDocument, string>({
      query: (id) => ({
        url: `/terms-and-conditions/documents/${id}`,
        method: "GET",
      }),
      providesTags: ["Document"],
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getTermsAndConditionsDocuments: builder.query<IDocument[], void>({
      query: () => ({
        url: `/terms-and-conditions/documents`,
        method: "GET",
      }),
      providesTags: ["Document"],
      transformResponse: (response: any) => {
        // Sort the items by their 'createdDate' property in ascending order
        const sortedItems = response?.data?.sort((a: any, b: any) => {
          if (!a._tsc || !b._tsc) return 0;

          return a._tsc - b._tsc;
        });

        const items = sortedItems?.map((item: any) => {
          return {
            ...item,
            created: item._tsc,
            modified: item._ts,
          };
        });

        return items;
      },
    }),
    addTermsAndConditionsDocument: builder.mutation<IDocument, IDocument>({
      query: (document) => ({
        url: `/terms-and-conditions/documents`,
        method: "POST",
        body: document,
      }),
      invalidatesTags: ["Document"],
      transformResponse: (response: any) => {
        const item = response?.data;

        return {
          ...item,
          created: item._tsc,
          modified: item._ts,
        };
      },
    }),
    addTermsAndConditionsDocumentContent: builder.mutation({
      query: ({ documentId, file }) => ({
        url: `/terms-and-conditions/documents/${documentId}/upload`,
        method: "PUT",
        body: file,
      }),
    }),
    getTermsAndConditions: builder.query<ContractExtractionResult[], IDocument>(
      {
        query: ({ id }) => ({
          url: `/terms-and-conditions/documents/${id}/articles`,
          method: "GET",
        }),
        providesTags: ["TermsAndConditions"],
        transformResponse: (response: any) => {
          // Sort the items by their 'createdDate' property in ascending order
          const sortedItems = response?.data?.sort((a: any, b: any) => {
            if (!a._tsc || !b._tsc) return 0;

            return a._tsc - b._tsc;
          });

          const items = sortedItems?.map((item: any) => {
            return {
              ...item,
              created: item._tsc,
              modified: item._ts,
            };
          });

          return items;
        },
      }
    ),
  }),
});

export const {
  useGetContractsQuery,
  useLazyGetContractsQuery,
  useGetContractQuery,
  useGetManagedContractQuery,
  useGetSigningContractsQuery,
  useGetMySigningTasksQuery,

  useAddContractMutation,
  useAddDocumentMutation,
  useGetDocumentQuery,
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
  useGetAttachmentsQuery,
  useGetDocumentContentQuery,
  useLazyGetDocumentContentQuery,
  useAddDocumentContentMutation,
  useAddDocumentContentFromDownloadUrlMutation,
  useDeleteContractMutation,
  useUpdateContractMutation,
  useGetSharePointConnectionsQuery,
  useAddSharePointConnectionMutation,
  useDeleteSharePointConnectionMutation,

  useAddConnectedDriveMutation,
  useGetConnectedDrivesQuery,
  useDeleteConnectedDriveMutation,

  useGetMessagesQuery,

  useGetMessageQuery,
  useGetMessageRepliesQuery,
  useDeleteMessageMutation,
  useAddMessageMutation,
  useUpdateMessageMutation,

  useGetNotesQuery,
  useGetMyNotesQuery,
  useGetNotesForContractQuery,

  useDeleteNoteMutation,
  useAddNoteMutation,
  useUpdateNoteMutation,

  useGetMessagesFeedForUserQuery,

  useGetLibraryKPIsQuery,
  useGetLibraryKPIQuery,
  useDeleteLibraryKPIMutation,
  useAddLibraryKPIMutation,
  useUpdateLibraryKPIMutation,

  useGetActivitiesQuery,
  useGetActivitiesForContractQuery,

  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,

  useGetSettingsQuery,
  useAddSettingsMutation,
  useUpdateSettingsMutation,

  useGetWebPubSubQuery,
  useGetTopVendorsQuery,
  useGetContractExpirationCountQuery,

  useGetClientsUnderManagementQuery,
  useGetContractsUnderManagementQuery,
  useGetTrustedPartnerInvitesQuery,
  useGetAccessTokenForClientQuery,
  useLazyGetAccessTokenForClientQuery,
  useGetTrustedPartnerTrustsQuery,

  useAddTrustedPartnerInviteMutation,
  useAddTrustedPartnerInviteAcceptedMutation,
  useAddTrustedPartnerInviteDeclinedMutation,
  useDeleteTrustedPartnerInviteItemMutation,
  useGetTrustedPartnerInviteResponsesQuery,

  useAddContractAIResponseMutation,
  useDeleteContractAIResponseMutation,
  useGetContractAIResponsesQuery,
  useUpdateUserMutation,

  useGetAssistantThreadQuery,
  useLazyGetAssistantThreadsQuery,
  useUpdateChatBotMutation,
  useAddChatBotMutation,

  useGetContractsByStatusQuery,

  useSyncContractsFromSharePointMutation,
  useSyncConnectedDriveMutation,

  useGetPromptsQuery,
  useLazyGetPromptsQuery,
  useDeletePromptMutation,
  useAddPromptMutation,
  useUpdatePromptMutation,

  useGetSubscriptionInfoQuery,
  useGetFoldersQuery,
  useAddFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,

  useGetAIOutputQuery,
  useLazyGetAIOutputQuery,
  useDeleteAIOutputMutation,

  useGetHumanDataVerificationsQuery,
  useAddHumanDataVerificationMutation,
  useUpdateHumanDataVerificationMutation,
  useDeleteHumanDataVerificationMutation,

  useGetContractCategoriesQuery,
  useDeleteContractCategoryMutation,
  useAddContractCategoryMutation,
  useUpdateContractCategoryMutation,

  useLazyGetTermsAndConditionsQuery,
  useGetTermsAndConditionsDocumentQuery,
  useGetTermsAndConditionsDocumentsQuery,
  useAddTermsAndConditionsDocumentMutation,
  useAddTermsAndConditionsDocumentContentMutation,

  useGetContractsInProgressQuery,
} = AzureApi;
