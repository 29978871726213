import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Divider as MuiDivider,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  CircularProgress,
  Tooltip,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

import {
  Check,
  Close,
  ExpandLess,
  ExpandMore,
  PlaylistAdd,
  PlaylistRemove,
  QuestionMark,
  SmartButton,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";

import {
  useDeleteAIOutputMutation,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import ContractPromptResponse from "./ContractPromptResponse";
import { IPrompt } from "../../../../types/prompt";
import {
  ContractExtractionResult,
  Paragraph,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
enum TabIndex {
  AI_ASSISTANT,
  ATTACHMENTS,
  ROLES,
  NOTES,
  AI_READ,
  SIGNEES,
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flexStart",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
  tabIndex: number;
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  border: "1px solid gray",
  // color: "rgba(255, 255, 255, 0.7)",

  "&.Mui-selected": {
    // color: "#fff",
    backgroundColor: "background.paper",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const RelevantParagraphs = ({
  paragraphs,
  tablesCount,
  selectParagraph,
}: {
  paragraphs: Paragraph[];
  tablesCount: number;
  selectParagraph: (paragraph: Paragraph) => void;
}) => {
  const { t } = useTranslation();

  const [deviation, setDeviation] = useState(0);
  const [minDeviation, setMinDeviation] = useState(0);
  const [maxDeviation, setMaxDeviation] = useState(1);
  const [highestScore, setHighestScore] = useState(0);
  const [step, setStep] = useState(0);
  const [marks, setMarks] = useState<{ value: number; label: string }[]>([]);

  useEffect(() => {
    if (paragraphs) {
      const newHighestScore = Math.max(
        ...paragraphs.filter((p) => p.score).map((p) => p.score)
      );
      const lowestScore = Math.min(
        ...paragraphs.filter((p) => p.score).map((p) => p.score)
      );

      const distance = newHighestScore - lowestScore;

      // divide the distance over 5 steps
      const step = distance / 2;
      const newMarks = [
        {
          value: 0,
          label: t("High"),
        },
        {
          value: step,
          label: t("Medium"),
        },
        {
          value: step * 2,
          label: t("Low"),
        },
      ];

      setDeviation(0);
      setStep(step);
      setMarks(newMarks);
      setHighestScore(newHighestScore);
      setMinDeviation(0);
      setMaxDeviation(newHighestScore - lowestScore);
    }
  }, [paragraphs]);

  const pageNums = paragraphs
    ?.flatMap((paragraph) => paragraph.boundingRegions)
    .map((r) => r.pageNumber)
    .reduce((acc: number[], curr) => {
      if (acc.indexOf(curr) === -1) {
        acc.push(curr);
      }
      return acc;
    }, []);

  pageNums.sort((a, b) => a - b);

  return (
    <Box>
      <Grid container direction="column" gap={3}>
        {/* <Box my={2} mr={8}>
          <Slider
            size="small"
            step={step}
            min={minDeviation}
            max={maxDeviation}
            aria-label="Sensitivity"
            value={deviation}
            marks={marks}
            onChange={(_, value) => setDeviation(value as number)}
          />
        </Box> */}
        {/* <Typography variant="caption" mt={3}>
          {t("Relevant_paragraphs", {
            count: paragraphs.length,
          })}
        </Typography> */}
        {pageNums?.map((pageNum) => {
          const paragraphsCurPage = paragraphs.filter(
            (p) =>
              p.boundingRegions.some((r) => r.pageNumber === pageNum) &&
              (p.score >= highestScore - deviation || !p.score)
          );

          if (!paragraphsCurPage || paragraphsCurPage.length === 0) {
            return null;
          }

          return (
            <Grid container direction="column" gap={1}>
              <Typography
                variant="caption"
                sx={{
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  // only allow 2 lines
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {t("Page")} {pageNum}
              </Typography>

              <Grid container direction="column">
                {paragraphsCurPage.map((paragraph, index) => (
                  <div>
                    <Button
                      // href={`#${paragraph.id}`}
                      variant="text"
                      onClick={() => selectParagraph(paragraph)}
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          // only allow 2 lines
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {paragraph.content}
                      </Typography>
                    </Button>
                  </div>
                ))}
              </Grid>
            </Grid>
          );
        })}

        <Box display="flex" justifyContent="center" gap={4}>
          {deviation > minDeviation && (
            <Button
              variant="text"
              onClick={() => {
                setDeviation(deviation - step);
              }}
              startIcon={<ExpandLess />}
              size="small"
            >
              {t("Show less")}
            </Button>
          )}

          {deviation < maxDeviation && (
            <Button
              variant="text"
              onClick={() => {
                setDeviation(deviation + step);
              }}
              startIcon={<ExpandMore />}
              size="small"
            >
              {t("Show more")}
            </Button>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export const Content = ({
  currentResult,
  selectParagraph,
}: {
  currentResult: PromptExtractionResult;
  selectParagraph: (paragraph: Paragraph) => void;
}) => {
  const { t } = useTranslation();

  const tablesCount = currentResult?.relevantParagraphs?.filter((r) =>
    r.id?.startsWith("table")
  ).length;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
      }}
    >
      <ContractPromptResponse text={currentResult?.extractionResult.details} />
      {/* 
      <Typography variant="caption" fontWeight={600}>
        {t("Sources", {
          count: currentResult?.relevantSections.length,
        })}
      </Typography> */}
      {currentResult?.relevantSections?.length > 0 && (
        <SimpleTreeView
        // defaultExpandedItems={[`sources-${currentResult?.promptID}`]}
        >
          <TreeItem2
            itemId={`sources-${currentResult?.promptID}`}
            label={t("Useful links")!}
            slotProps={{
              label: {
                style: {
                  fontWeight: 600,
                  fontSize: 12,
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              },
            }}
          >
            <Grid container direction="column" gap={0}>
              <Grid container direction="column" gap={1}>
                <Box pl={6}>
                  <RelevantParagraphs
                    // paragraphs={section.paragraphs?.filter(
                    //   (p) => p.id !== firstParagraphInSection.id // exclude the first paragraph since it is already shown
                    // )}
                    paragraphs={currentResult?.relevantParagraphs}
                    tablesCount={tablesCount}
                    selectParagraph={selectParagraph}
                  />
                </Box>
              </Grid>
            </Grid>
          </TreeItem2>
        </SimpleTreeView>
      )}

      {(!currentResult?.relevantSections ||
        currentResult?.relevantSections?.length === 0) && (
        <>
          <Typography variant="caption">
            {t("Relevant_paragraphs", {
              count: currentResult?.relevantParagraphs.length,
            })}
            {tablesCount > 0 &&
              ` ${t("and")} ${t("Relevant_tables", {
                count: tablesCount,
              })}`}
            :
          </Typography>

          <RelevantParagraphs
            paragraphs={currentResult?.relevantParagraphs}
            tablesCount={tablesCount}
            selectParagraph={selectParagraph}
          />
        </>
      )}
    </div>
  );
};

const ContractPromptAccordion = ({
  contract,
  mode,
  selectParagraph,
  prompt,
  isExpanded,
  aiOutputs,
  infoFound,
  documents,
}: {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  selectParagraph: (
    paragraph?: Paragraph,
    prompt?: IPrompt,
    aiOutput?: ContractExtractionResult
  ) => void;
  prompt: IPrompt;
  isExpanded: boolean;
  infoFound: boolean;
  documents: IDocument[];
}) => {
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const [updateContract, { isLoading: isUpdatingContract }] =
    useUpdateContractMutation();

  const [deleteAIOutput] = useDeleteAIOutputMutation();

  const [aiOutputsForPrompt, setAiOutputsForPrompt] = useState<
    ContractExtractionResult[]
  >([]);

  const [latestAiOutput, setLatestAiOutput] =
    useState<ContractExtractionResult>();

  const [latestPromptResult, setLatestPromptResult] =
    useState<PromptExtractionResult>();

  const [isExcluded, setIsExcluded] = useState(false);

  useEffect(() => {
    if (contract?.exludedPromptIds) {
      if (prompt.id) {
        setIsExcluded(contract.exludedPromptIds.includes(prompt.id));
      }
    }
  }, [contract.exludedPromptIds]);

  useEffect(() => {
    if (aiOutputs && aiOutputs.length > 0) {
      const promptResults = aiOutputs.filter((a) =>
        a.prompts?.some((p) => p.promptID === prompt.id)
      );

      if (promptResults?.length > 0) {
        setLatestAiOutput(promptResults[0]);
      }
    }

    if (aiOutputs) {
      setAiOutputsForPrompt(
        aiOutputs.filter((a) =>
          a.prompts?.some((p) => p.promptID === prompt.id)
        )
      );
    }
  }, [aiOutputs]);

  useEffect(() => {
    if (latestAiOutput) {
      setLatestPromptResult(
        latestAiOutput.prompts?.find((p) => p.promptID === prompt.id)
      );
    }
  }, [latestAiOutput]);

  const verificationRecords = verifications?.filter(
    (v) => v.field === prompt.id
  );

  const latestVerificationRecord = verificationRecords?.find(
    (v) => v.aiOutputId === latestAiOutput?.id
  );

  const latestResultIsVerified =
    latestVerificationRecord?.isCorrect !== undefined;

  const exclude = () => {
    if (!prompt.id) {
      return;
    }

    setIsExcluded(true);
    updateContract({
      ...contract,
      exludedPromptIds: [...(contract.exludedPromptIds || []), prompt.id],
    });
  };

  const include = () => {
    if (!prompt.id) {
      return;
    }

    setIsExcluded(false);
    updateContract({
      ...contract,
      exludedPromptIds:
        contract.exludedPromptIds?.filter((id) => id !== prompt.id) || [],
    });
  };

  const handleExclusion = () => {
    if (isExcluded) {
      include();
    } else {
      exclude();
    }
  };

  return (
    <Accordion
      key={`accordion-${prompt.id}`}
      onChange={(e, expanded) =>
        selectParagraph(
          undefined,
          expanded ? prompt : undefined
          // latestAiOutput
        )
      }
      expanded={isExpanded}
      id={`panel-${prompt.id}-block`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${prompt.id}`}
        id={`panel-${prompt.id}-header`}
      >
        <ListItem disablePadding disableGutters>
          <ListItemIcon>
            <StyledBadge
              badgeContent={
                latestResultIsVerified && !infoFound ? (
                  <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : latestResultIsVerified ? (
                  <Check sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : aiOutputs?.some((a) =>
                    a.prompts?.some((p) => p.promptID === prompt.id)
                  ) ? (
                  <QuestionMark sx={{ maxWidth: 8, maxHeight: 16 }} />
                ) : (
                  <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
                )
              }
              color={
                latestResultIsVerified && !infoFound
                  ? "error"
                  : latestResultIsVerified
                  ? "success"
                  : aiOutputs?.some((a) =>
                      a.prompts?.some((p) => p.promptID === prompt.id)
                    )
                  ? "info"
                  : "error"
              }
              showZero
            >
              <SmartButton />
            </StyledBadge>
          </ListItemIcon>
          <ListItemText
            primary={
              <Grid container alignItems="center" gap={1}>
                {prompt?.title ? t(prompt.title) : t("Unknown")}
                {latestVerificationRecord?.isCorrect ? (
                  <ThumbUp fontSize="small" color="success" />
                ) : latestVerificationRecord?.isCorrect === false ? (
                  <ThumbDown fontSize="small" color="error" />
                ) : (
                  <QuestionMark fontSize="small" color="info" />
                )}
              </Grid>
            }
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <ContractPromptResponse
          text={
            contract?.prompts?.find(
              (p) => p.id === prompt.id && p.extractionResult
            )?.text || ""
          }
        />
        {aiOutputsForPrompt?.length > 0 && latestAiOutput && (
          <Box my={5}>
            <SimpleTreeView
              // defaultExpandedItems={[
              //   `ai-output-items`,
              //   `${latestAiOutput?.id}`,
              // ]}
              onExpandedItemsChange={(e, itemIds) => {
                console.log(e);

                if (itemIds?.length > 0) {
                  const expandedAiOutput = aiOutputsForPrompt.find(
                    (a) => a.id === itemIds[0]
                  );
                  selectParagraph(undefined, prompt, expandedAiOutput);
                }
              }}
            >
              <TreeItem2
                itemId={`ai-output-items-${prompt.id}`}
                label={t("AI_suggestion", {
                  count: aiOutputsForPrompt?.length,
                })}
                slotProps={{
                  content: {
                    style: {
                      paddingTop: 10,
                      paddingBottom: 10,
                    },
                  },
                }}
              >
                {aiOutputs?.map((aiOutput, i) => {
                  // if (aiOutput.id === latestAiOutput?.id) {
                  //   // skip the latest ai output as it is already shown
                  //   return null;
                  // }

                  const currentResult = aiOutput.prompts?.find(
                    (p) => p.promptID === prompt.id
                  );

                  if (!currentResult) {
                    return null;
                  }

                  const verificationRecord = verifications?.find(
                    (v) => v.field === prompt.id && v.aiOutputId === aiOutput.id
                  );

                  const document = documents.find(
                    (d) => d.id === aiOutput.documentId
                  );

                  return (
                    <TreeItem2
                      itemId={`${aiOutput.id}-${i}`}
                      label={
                        <Grid container justifyContent="space-between">
                          <Typography>
                            {aiOutput.processedAt
                              ? t("date.dateTime", {
                                  date: new Date(aiOutput.processedAt),
                                })
                              : "No processing date"}
                          </Typography>
                          {/* {documents.length > 1 && (
                            <DocumentChip document={document} />
                          )} */}
                          {/* <Button onClick={() => deleteAIOutput(aiOutput)}>
                            Delete
                          </Button> */}
                        </Grid>
                      }
                      style={{
                        marginBottom: 5,
                        gap: 5,
                      }}
                      slotProps={{
                        content: {
                          style: {
                            paddingTop: 10,
                            paddingBottom: 10,
                          },
                        },
                      }}
                    >
                      <Box my={2} display="flex" flexDirection="column" gap={2}>
                        {document && documents?.length > 1 && (
                          <div>
                            <Chip
                              label={
                                document.type === "attachment"
                                  ? document.name
                                  : t("Main document")
                              }
                              color="primary"
                              size="small"
                              // icon={<AttachFile />}
                              variant="outlined"
                            />
                          </div>
                        )}

                        {verificationRecord && (
                          <VerifiedAISuggestion
                            verificationRecord={verificationRecord}
                          />
                        )}

                        <AISuggestion
                          contract={contract}
                          mode={mode}
                          isAiSuggestion={!verificationRecord}
                          collapsible={false}
                          isVerified={
                            verificationRecord?.isCorrect !== undefined
                          }
                          field="prompts"
                          prompt={prompt}
                          value={{
                            id: prompt.id,
                            text:
                              currentResult?.extractionResult?.details || "",
                            extractionResult: currentResult,
                          }}
                          aiInputId={aiOutput.aiInputId}
                          aiOutputId={aiOutput.id}
                          content={
                            <Content
                              currentResult={currentResult}
                              selectParagraph={(paragraph) => {
                                selectParagraph(paragraph, prompt, aiOutput);
                              }}
                            />
                          }
                          processedAt={aiOutput?.processedAt}
                        />
                      </Box>
                    </TreeItem2>
                  );
                })}
              </TreeItem2>
            </SimpleTreeView>
          </Box>
        )}
      </AccordionDetails>
      {mode === DialogMode.Edit && (
        <AccordionActions>
          <Tooltip
            title={
              isExcluded
                ? t("Include prompt in contract description")
                : t("Exclude prompt from contract description")
            }
          >
            <Button
              variant="text"
              startIcon={
                isUpdatingContract ? (
                  <CircularProgress size={12} />
                ) : isExcluded ? (
                  <PlaylistAdd />
                ) : (
                  <PlaylistRemove />
                )
              }
              onClick={handleExclusion}
              disabled={isUpdatingContract}
            >
              {isExcluded
                ? t("Include prompt in contract")
                : t("Exclude prompt from contract")}
            </Button>
          </Tooltip>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default ContractPromptAccordion;
