import { useState } from "react";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { IContract } from "../../../../types/contract";
import AzureADAvatar from "../../../../components/avatars/AzureADAvatar";
import { DialogMode } from "../../../../types/dialogmode";
import ContractRoleDialog from "../../../../components/dialogs/ContractRoleDialog";

interface ISidebarRolesProps {
  contract: IContract;

  hideTitle?: boolean;
  readonly?: boolean;
}
function SidebarRoles({
  contract,

  hideTitle,
  readonly,
}: ISidebarRolesProps) {
  const { t } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const fields: any[] = ["adminIds", "managerIds", "ownerIds", "readerIds"];

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box width="100%">
        {!hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Roles")}
          </Typography>
        )}

        <Grid container spacing={3} direction="column" px={2}>
          {fields.map((field, i) => {
            const persons =
              field === "adminIds"
                ? contract.adminIds
                : field === "managerIds"
                ? contract.managerIds
                : field === "ownerIds"
                ? contract.ownerIds
                : field === "readerIds"
                ? contract.readerIds
                : [];

            const label =
              field === "adminIds"
                ? t("Administrator")
                : field === "managerIds"
                ? t("Manager")
                : field === "ownerIds"
                ? t("Owner")
                : field === "readerIds"
                ? t("Reader")
                : "";

            return (
              <Grid
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(-1)}
                onBlur={() => setHoveredIndex(-1)}
                key={`role-${field}`}
              >
                <Box
                  display="flex"
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {(!readonly || persons?.length > 0) && (
                    <Typography>{label}</Typography>
                  )}

                  {!readonly && (
                    <ContractRoleDialog
                      mode={
                        persons?.length === 0 ? DialogMode.Add : DialogMode.Edit
                      }
                      contract={contract}
                      showMenuIcon={i === hoveredIndex}
                      field={field}
                    />
                  )}
                </Box>

                <Grid container gap={2} direction="column" p={2}>
                  {persons?.map((userId) => (
                    <Grid key={userId}>
                      <AzureADAvatar userId={userId} small />
                    </Grid>
                  ))}
                  {!readonly && (!persons || persons?.length === 0) && (
                    <Grid>
                      <ContractRoleDialog
                        mode={DialogMode.Add}
                        contract={contract}
                        showMenuIcon
                        field={field}
                        iconOnly
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default SidebarRoles;
