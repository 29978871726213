import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Drawer,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Paper,
  Stack,
  Toolbar,
  alpha,
  CardActionArea,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { green, grey, red } from "@mui/material/colors";
import { spacing } from "@mui/system";

import PromptDialog from "../../../../components/dialogs/PromptDialog";
import { DialogMode } from "../../../../types/dialogmode";
import { CardData } from ".";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; hideLabel?: boolean }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {!props.hideLabel && (
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

const PromptMenuItem = styled(ListItemButton)<{
  isSelected?: boolean;
}>`
  ${(props) =>
    props.selected &&
    `
    background-color: ${props.theme.palette.primary.main} !important;
    color: ${props.theme.palette.common.white};
    height: 100%;
  `}
`;

// const PromptListItem = styled(ListItemButton)(({ selected, theme }) => ({
//   backgroundColor: selected
//     ? `${theme.palette.neutral.secondary} !important`
//     : "inherit",
//   borderRadius: 3,

//   "& .Mui-selected": {
//     paddingLeft: 24,
//     paddingRight: 24,
//   },
//   "& .MuiListItemText-root": {
//     // color: selected ? theme.palette.primary.contrastText : "inherit",
//     fontWeight: selected ? 600 : 400,
//   },
//   "& .MuiSvgIcon-root": {
//     fontSize: 20,
//   },
// }));

export const BorderLinearProgress = styled(LinearProgressWithLabel)(
  ({ theme }) => ({
    height: 10,
    borderRadius: 5,

    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
  })
);

type PromptsSideBarProps = {
  data: any[];
  selectedCard: CardData;
  setSelectedCard: (data: CardData | null) => void;
  overallScore: number;
  totalContractsRead: number;
};

function PromptsSideBar({
  data,
  selectedCard,
  setSelectedCard,
  overallScore,
  totalContractsRead,
}: PromptsSideBarProps) {
  const { t } = useTranslation();

  return (
    <Grid sx={{ backgroundColor: "background.paper" }}>
      <Typography
        variant="h5"
        children="AI Contract Manager"
        pt={4}
        px={4}
        pb={2}
      />

      <Card sx={{ margin: 2 }} variant="outlined">
        <CardActionArea onClick={() => setSelectedCard(null)}>
          <CardContent sx={{ pt: 6 }}>
            <BorderLinearProgress variant="determinate" value={overallScore} />
            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              pt={2}
              gap={0.7}
              flexDirection="column"
            >
              <Grid container justifyContent="center">
                <Typography variant="h3">
                  {overallScore?.toString()}%
                </Typography>
                <Typography variant="h4" pt={0.6}>
                  {t("Complete")}
                </Typography>
              </Grid>
              <Typography variant="body2">
                {totalContractsRead === 1
                  ? t("1 contract read")
                  : t("X contracts read", { count: totalContractsRead })}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <List sx={{ height: "80vh", overflow: "auto" }}>
        <ListItem>
          <PromptDialog mode={DialogMode.Add} />
        </ListItem>

        {data?.map((d) => {
          if (d.prompt.hidden) return null;

          const totalContracts =
            d.contractsWithPrompt?.length + d.contractsWithoutPrompt?.length ||
            0;

          const percentage = Math.round(
            (d.contractsWithPrompt?.length / totalContracts) * 100
          );

          return (
            <ListItemButton
              sx={{ margin: 2, borderRadius: 2 }}
              onClick={() => setSelectedCard(d)}
              key={`card-${d.prompt.key}`}
              selected={selectedCard?.prompt.id === d.prompt.id}
            >
              <ListItemText
                primary={d.prompt?.title ? t(d.prompt.title) : ""}
                secondary={
                  <BorderLinearProgress
                    variant="determinate"
                    value={percentage}
                  />
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Grid>
  );
}

export default PromptsSideBar;
