import { Box, Typography } from "@mui/material";
import Markdown from "react-markdown";

interface IContractPromptResponseProps {
  text: string;
}
function ContractPromptResponse({ text }: IContractPromptResponseProps) {
  return (
    <Box>
      <Typography variant="body2">
        {/* color="textSecondary" */}
        <Markdown
          components={{
            p: ({ node, ...props }) => (
              <p
                style={{
                  marginBlockStart: "0.5rem",
                  marginBlockEnd: "0.5rem",
                }}
                {...props}
              />
            ),
            code: ({ node, ...props }) => (
              <code
                style={{
                  fontFamily: "Inter",
                  whiteSpace: "pre-wrap",
                }}
                {...props}
              />
            ),
          }}
        >
          {text}
        </Markdown>

        {/* {response?.locationInText && (
          <Button
            onClick={() => {
              // const location = JSON.parse(response.locationInText);
              const location = "Inflatiecorrectie";
              props.setSearchForText && props.setSearchForText(location);
            }}
          >
            {response?.locationInText}
          </Button>
        )} */}
      </Typography>
    </Box>
  );
}

export default ContractPromptResponse;
