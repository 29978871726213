import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  AlertTitle,
  Icon,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import MicrosoftIcon from "../../icons/MicrosoftIcon";

// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";
import { AuthContext as AuthContextMSAL } from "../../contexts/MSALContext";
import { AuthContext } from "../../contexts/MainAuthContext";
import { useTranslation } from "react-i18next";
import { ChevronLeftRounded, Email } from "@mui/icons-material";
import { useGetSubscriptionInfoQuery } from "../../redux/slices/indexApiSlice";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { msalLogin, firebaseLogin, error, passwordLogin } = useAuth();
  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery();

  const context = useContext(AuthContext);

  const handlePasswordSubmit = async ({ submitForm, setFieldValue }: any) => {
    setFieldValue("loginProvider", "password").then(() => {
      submitForm(); // Submit the form
    });
  };

  const handleMSALSubmit = async ({ submitForm, setFieldValue }: any) => {
    setFieldValue("loginProvider", "MSAL").then(() => {
      submitForm(); // Submit the form
    });
  };

  const handleGoogleSubmit = async ({ submitForm, setFieldValue }: any) => {
    setFieldValue("loginProvider", "Google").then(() => {
      submitForm(); // Submit the form
    });
  };

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <Box>
      <Formik
        initialValues={{
          email: "",
          password: "",
          loginProvider: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().when("loginProvider", (loginProvider) =>
            loginProvider.includes("MSAL") || loginProvider.includes("Google")
              ? Yup.string()
              : Yup.string()
                  .email("Must be a valid email")
                  .required(t("Email is required")!)
          ),
          password: Yup.string().when("loginProvider", (loginProvider) =>
            loginProvider.includes("MSAL") || loginProvider.includes("Google")
              ? Yup.string()
              : Yup.string().max(255).required(t("Password is required")!)
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            localStorage.setItem("loginType", values.loginProvider);
            if (values.loginProvider === "MSAL") {
              await msalLogin();
            } else if (values.loginProvider === "Google") {
              await firebaseLogin(); // firebase?.signInWithGoogle();
            } else if (values.loginProvider === "password") {
              await passwordLogin(values.email, values.password);
            }

            navigate("/");
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          submitForm,
        }) => (
          <>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}

            {subscriptionInfo && subscriptionInfo?.isActive !== true && (
              <Alert severity="warning">
                <AlertTitle>{t("Subscription expired")}</AlertTitle>
                {t("Please renew your subscription to continue using the app")}
                <br />
              </Alert>
            )}

            <Stack gap={3}>
              <Box pt={6}>
                <Button
                  disabled={isSubmitting}
                  fullWidth
                  aria-label="Microsoft 365"
                  variant="contained"
                  size="large"
                  name="loginProvider"
                  onClick={() =>
                    handleMSALSubmit({ submitForm, setFieldValue })
                  }
                >
                  <MicrosoftIcon
                    sx={{
                      width: 32,
                      height: "auto",
                      paddingRight: "12px",
                    }}
                  />

                  <Typography variant="button" sx={{ textAlign: "center" }}>
                    {t("Continue with Microsoft 365 Account")}
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
      {error && (
        <Alert mt={2} mb={3} severity="warning">
          {error}
        </Alert>
      )}
    </Box>
  );
}

export default SignIn;
