import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider as MuiDivider,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { SmartButton } from "@mui/icons-material";

import { IPrompt } from "../../../../types/prompt";
import {
  ContractExtractionResult,
  Paragraph,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import { Content } from "./ContractPromptAccordion";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
enum TabIndex {
  AI_ASSISTANT,
  ATTACHMENTS,
  ROLES,
  NOTES,
  AI_READ,
  SIGNEES,
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flexStart",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
  tabIndex: number;
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  border: "1px solid gray",
  // color: "rgba(255, 255, 255, 0.7)",

  "&.Mui-selected": {
    // color: "#fff",
    backgroundColor: "background.paper",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const ContractSuggestedPromptAccordion = ({
  contract,
  mode,
  selectParagraph,
  suggestedPrompt,
  isExpanded,
  aiOutputs,
  infoFound,
  documents,
}: {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  selectParagraph: (paragraph?: Paragraph) => void;
  suggestedPrompt: Partial<IPrompt> & { relevantParagraphs: Paragraph[] };
  isExpanded: boolean;
  infoFound: boolean;
  documents: IDocument[];
}) => {
  const { t } = useTranslation();

  const [latestAiOutput, setLatestAiOutput] =
    useState<ContractExtractionResult>();

  useEffect(() => {
    if (aiOutputs && aiOutputs.length > 0) {
      const promptResults = aiOutputs.filter(
        (a) => a.suggestedPrompts && a.suggestedPrompts?.length > 0
      );

      if (promptResults?.length > 0) {
        setLatestAiOutput(promptResults[0]);
      }
    }
  }, [aiOutputs]);

  const extractionResult: PromptExtractionResult = {
    promptID: suggestedPrompt.id || "",
    promptTitle: suggestedPrompt.title || "",
    promptBody: suggestedPrompt.body || "",
    extractionResult: {
      infoFound: true,
      details: suggestedPrompt.body || "",
    },
    rawExtractionResult: {},
    relevantParagraphs: suggestedPrompt.relevantParagraphs || [],
    relevantPages: [],
    relevantSections: [],
  };

  return (
    <Accordion
      key={`accordion-${suggestedPrompt.id}`}
      id={`panel-${suggestedPrompt.id}-block`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${suggestedPrompt.id}`}
        id={`panel-${suggestedPrompt.id}-header`}
      >
        <ListItem disablePadding disableGutters>
          <ListItemIcon>
            <SmartButton />
          </ListItemIcon>
          <ListItemText
            primary={
              suggestedPrompt?.title ? t(suggestedPrompt.title) : t("Unknown")
            }
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        {latestAiOutput && suggestedPrompt && (
          <Box>
            <Content
              currentResult={extractionResult}
              selectParagraph={(paragraph) => {
                selectParagraph(paragraph);
              }}
            />
          </Box>
        )}
      </AccordionDetails>
      {/* {mode === DialogMode.Edit && (
        <AccordionActions>
          <Tooltip
            title={
              isExcluded
                ? t("Include prompt in contract description")
                : t("Exclude prompt from contract description")
            }
          >
            <Button
              variant="text"
              startIcon={
                isUpdatingContract ? (
                  <CircularProgress size={12} />
                ) : isExcluded ? (
                  <PlaylistAdd />
                ) : (
                  <PlaylistRemove />
                )
              }
              onClick={handleExclusion}
              disabled={isUpdatingContract}
            >
              {isExcluded
                ? t("Include prompt in contract")
                : t("Exclude prompt from contract")}
            </Button>
          </Tooltip>
        </AccordionActions>
      )} */}
    </Accordion>
  );
};

export default ContractSuggestedPromptAccordion;
