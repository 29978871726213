import styled from "@emotion/styled";
import {
  ArrowForwardIosRounded,
  SendRounded,
  ExpandMore as ExpandMoreIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from "@mui/icons-material";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fab,
  Divider as MuiDivider,
  Typography,
  AccordionProps,
  AccordionSummaryProps,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import AzureADAvatar from "../avatars/AzureADAvatar";
import { AssistantThread, IChatBotHistory } from "../../types/chatBot";
import { convertCreatedDate } from "../../utils/string";
import { useLazyGetAssistantThreadsQuery } from "../../redux/slices/indexApiSlice";
import UserAvatar from "../avatars/UserAvatar";
import { t, use } from "i18next";
import { lighten } from "polished";
import OwlIcon from "../../icons/OwlIcon";
import OwlAvatarIcon from "../../icons/OwlAvatarIcon";
import { useParams } from "react-router-dom";
import ContractAvatar from "../avatars/ContractAvatar";
import VendorAvatar from "../avatars/VendorAvatar";
import ChatBotHistoryItem from "./ChatHistoryItem";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Divider = styled(MuiDivider)<{ mx?: number }>(spacing);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 10px;
  // background-color: ${(props) => props.theme.palette.neutral.primary};
  // background-color: ${(props) => props.theme.palette.common.white};
  // background: ${(props) => props.theme.palette.primary.main};
  // background: ${(props) => props.theme.palette.primary.gradient};
  // background-image: url("/static/img/backgrounds/dark-raster.png");
  // background-position: top center;
  // background-size: initial;
`;

// Main Component
type Props = {
  onConversationClick: (thread: AssistantThread) => any;
  onCreateChatBox: () => any;
  refetch: () => void;
};

const AssistantThreads: React.FC<Props> = ({
  onConversationClick,
  onCreateChatBox,
  refetch,
}) => {
  const { contractId, vendorId } = useParams();

  const [threads, setThreads] = useState<AssistantThread[]>([]);
  const [remainingThreads, setRemainingThreads] = useState<AssistantThread[]>(
    []
  );
  const [selected, setSelected] = useState<AssistantThread[]>([]);

  const [getThreads, { data: responses = [], isSuccess, isLoading }] =
    useLazyGetAssistantThreadsQuery();

  useEffect(() => {
    const newRemainingConversations = responses?.filter(
      (c) => !threads?.some((r) => r.id === c.id)
    );

    setRemainingThreads(newRemainingConversations);
  }, [threads, responses]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (contractId) {
        // we are on a contract page
        const filteredResponses = responses?.filter(
          (r: any) => r.contractId === contractId
        );

        setThreads(filteredResponses);
      } else if (vendorId) {
        // we are on a vendor page
        const filteredResponses = responses?.filter(
          (r: any) => r.vendorId === vendorId
        );

        setThreads(filteredResponses);
      } else {
        setThreads(responses);
      }
    }
  }, [isLoading, isSuccess, contractId, vendorId]);

  // useEffect(() => {
  //   getChatHistory();
  // }, []);

  useEffect(() => {
    getThreads();
  }, [refetch]);

  return (
    <Box sx={{ backgroundColor: "background.paper", height: "100%" }}>
      <Box sx={{ paddingBottom: 24 }}>
        {selected.length > 0 && (
          <Typography p={2}>
            {t("selected", {
              count: selected.length,
            })}
          </Typography>
        )}

        {!isLoading ? (
          <>
            {contractId && (
              <>
                {threads?.length > 0 ? (
                  <Typography variant="subtitle2" pt={3} pl={3}>
                    {t("Conversations regarding this contract")}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" p={3}>
                    {t("No conversations regarding this contract")}
                  </Typography>
                )}
              </>
            )}

            {vendorId && (
              <>
                {threads?.length > 0 ? (
                  <Typography variant="subtitle2" pt={3} pl={3}>
                    {t("Conversations regarding this vendor")}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" p={3}>
                    {t("No conversations regarding this vendor")}
                  </Typography>
                )}
              </>
            )}

            {threads?.map((chatBotHistory, i) => (
              <strong className="no-cursor" key={chatBotHistory.id}>
                <ChatBotHistoryItem
                  item={chatBotHistory}
                  onClick={() => onConversationClick(chatBotHistory)}
                  onChange={(chatBotHistory, checked) => {
                    if (checked) {
                      setSelected([...selected, chatBotHistory]);
                    } else {
                      setSelected(
                        selected.filter((s) => s.id !== chatBotHistory.id)
                      );
                    }
                  }}
                />

                {i < threads.length - 1 && <Divider mx={4}></Divider>}
              </strong>
            ))}

            {remainingThreads?.length > 0 && (
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {remainingThreads?.length > 0 && (
                    <Typography variant="subtitle2">
                      {t("Other conversations")}
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {remainingThreads?.map((chatBotHistory, i) => (
                    <strong className="no-cursor" key={chatBotHistory.id}>
                      <ChatBotHistoryItem
                        item={chatBotHistory}
                        onClick={() => onConversationClick(chatBotHistory)}
                        onChange={(chatBotHistory, checked) => {
                          if (checked) {
                            setSelected([...selected, chatBotHistory]);
                          } else {
                            setSelected(
                              selected.filter((s) => s.id !== chatBotHistory.id)
                            );
                          }
                        }}
                      />

                      {i < remainingThreads.length - 1 && (
                        <Divider mx={4}></Divider>
                      )}
                    </strong>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={10}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      <strong className="no-cursor">
        <Fab
          variant="extended"
          color="primary"
          onClick={onCreateChatBox}
          sx={{
            position: "absolute",
            bottom: 100,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <SendRounded sx={{ mr: 1 }} /> {t("Send_us_a_message")}
        </Fab>
      </strong>

      <Box
        display="flex"
        justifyContent="center"
        height={80}
        sx={{ bottom: 80, position: "absolute", margin: "0 auto" }}
      ></Box>
    </Box>
  );
};

export default AssistantThreads;
