import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Alert,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { ArrowForwardIosSharp, ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  useAddHumanDataVerificationMutation,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import useAuth from "../../../../hooks/useAuth";
import { IPrompt } from "../../../../types/prompt";
import { DialogMode } from "../../../../types/dialogmode";
import HumanDataVerificationDialog from "./HumanDataVerificationDialog";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function AISuggestion({
  contract,
  mode,
  isVerified,
  isAiSuggestion,
  content,
  processedAt,
  field,
  value,
  field2,
  value2,
  prompt,
  aiInputId,
  aiOutputId,
  collapsible,
}: {
  contract: IContract;
  mode: DialogMode;
  isVerified?: boolean;
  isAiSuggestion: boolean;
  content: React.ReactNode;
  processedAt?: string;
  field: string;
  aiInputId: string;
  aiOutputId: string;
  value: any;
  field2?: string;
  value2?: any;
  prompt?: IPrompt;
  collapsible?: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);

  const [updateContract] = useUpdateContractMutation();
  const [addVerification] = useAddHumanDataVerificationMutation();

  const confirmAiSuggestion = async () => {
    if (field === "prompts") {
      const newPrompts = contract.prompts ? [...contract.prompts] : [];
      if (prompt?.id && newPrompts.some((p) => p.id === prompt?.id)) {
        // overwrite the prompt
        const index = newPrompts.findIndex((p) => p.id === prompt.id);
        newPrompts[index] = value;
      } else {
        // add the prompt
        newPrompts.push(value);
      }

      const updatedContract: IContract = {
        ...contract,
        prompts: newPrompts,
      };

      await updateContract(updatedContract);

      // await addVerification({
      //   contractId: contract.id,
      //   aiOutputId: aiOutputId,
      //   aiInputId: aiInputId,
      //   field: prompt?.id || "prompts",
      //   isCorrect: true,
      //   verifiedBy: user.displayName,
      //   verifiedDate: new Date().toUTCString(),
      // });
    } else {
      const updatedContract: IContract = {
        ...contract,
        [field]: value,
      };

      if (field2) {
        (updatedContract as any)[field2] = value2;
      }

      await updateContract(updatedContract);

      // await addVerification({
      //   contractId: contract.id,
      //   aiOutputId: aiOutputId,
      //   aiInputId: aiInputId,
      //   field: field,
      //   isCorrect: true,
      //   verifiedBy: user.displayName,
      //   verifiedDate: new Date().toUTCString(),
      // });
    }
  };

  const declineAiSuggestion = async (comments: string) => {
    if (!contract || mode !== DialogMode.Edit) return;

    // if (field === "prompts") {
    //   await addVerification({
    //     contractId: contract.id,
    //     aiOutputId: aiOutputId,
    //     aiInputId: aiInputId,
    //     field: prompt?.id || "prompts",
    //     comments: comments,
    //     isCorrect: false,
    //     verifiedBy: user.displayName,
    //     verifiedDate: new Date().toUTCString(),
    //   });
    // } else {
    //   addVerification({
    //     contractId: contract.id,
    //     aiOutputId: aiOutputId,
    //     aiInputId: aiInputId,
    //     field: field,
    //     comments: comments,
    //     isCorrect: false,
    //     verifiedBy: user.displayName,
    //     verifiedDate: new Date().toUTCString(),
    //   });
    // }
  };

  if (!value) {
    return null;
  }

  return (
    <>
      {collapsible ? (
        <Grid size={12}>
          <Accordion>
            <AccordionSummary>
              <Typography variant="body1">{t("Show AI suggestion")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Alert
                sx={{ mt: 2 }}
                severity={isVerified && !isAiSuggestion ? "success" : "info"}
                variant="outlined"
                icon={<OwlAvatarIcon />}
              >
                {content}

                {processedAt && (
                  <Box mt={2}>
                    <Typography variant="caption" color="textDisabled">
                      {t("Processed at", {
                        date: new Date(processedAt),
                      })}
                    </Typography>
                  </Box>
                )}
              </Alert>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ) : (
        <Grid size={12}>
          <Alert
            severity={isVerified && !isAiSuggestion ? "success" : "info"}
            variant="outlined"
            icon={<OwlAvatarIcon />}
          >
            {content}
            {isAiSuggestion && !isVerified && mode === DialogMode.Edit && (
              <Grid
                container
                pt={2}
                direction="row"
                justifyContent="center"
                alignContent={"center"}
                gap={2}
              >
                <HumanDataVerificationDialog
                  mode={DialogMode.Add}
                  initialValues={{
                    contractId: contract.id,
                    aiOutputId,
                    aiInputId,
                    field:
                      field === "prompts" ? prompt?.id || "prompts" : field,
                    isCorrect: true,
                  }}
                  onSuccess={() => confirmAiSuggestion()}
                  button={{
                    startIcon: <ThumbUp />,
                    children: t("This is correct"),
                  }}
                />

                <HumanDataVerificationDialog
                  mode={DialogMode.Add}
                  initialValues={{
                    contractId: contract.id,
                    aiOutputId,
                    aiInputId,
                    field:
                      field === "prompts" ? prompt?.id || "prompts" : field,
                    isCorrect: false,
                  }}
                  button={{
                    startIcon: <ThumbDown />,
                    children: t("This is incorrect"),
                  }}
                />
              </Grid>
            )}

            {processedAt && (
              <Box mt={2}>
                <Typography variant="caption" color="textDisabled">
                  {t("Processed at", { date: new Date(processedAt) })}
                </Typography>
              </Box>
            )}
          </Alert>
        </Grid>
      )}

      {/* <HumanDataVerificationDialog
        open={openCommentsDialog}
        mode={DialogMode.Add}
        onClose={() => setOpenCommentsDialog(false)}
        onConfirm={(comments) => {
          setOpenCommentsDialog(false);
          declineAiSuggestion(comments);
        }}
      /> */}
    </>
  );
}

export default AISuggestion;
