import { useTranslation } from "react-i18next";
import { Box, Typography, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import { NumericFormat } from "react-number-format";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import { useGetHumanDataVerificationsQuery } from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import { DialogMode } from "../../../../types/dialogmode";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { IDocument } from "../../../../types/document";

interface IContractFinancialsProps {
  contract: IContract;
  documents: IDocument[];
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
}

export const FinancialsCard = ({
  currency,
  totalValue,
  paymentTerms,
  explanation,
  viewMode,
}: {
  currency?: string;
  totalValue?: number;
  paymentTerms?: string;
  explanation?: string;
  viewMode?: "compact" | "full";
}) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid container alignContent="center">
        {currency && totalValue ? (
          new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: currency,
            compactDisplay: "long",
            maximumFractionDigits: 0,
          }).format(totalValue)
        ) : (
          <NumericFormat
            value={totalValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"? "}
          />
        )}
      </Grid>

      {viewMode !== "compact" && (
        <>
          {paymentTerms && (
            <Typography pt={1} variant="body1">
              {paymentTerms}
            </Typography>
          )}

          {explanation && (
            <Typography pt={1} variant="body1">
              {explanation}
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
};

function ContractFinancials({ ...props }: IContractFinancialsProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  return (
    <Grid container direction="column" pb={2} gap={4}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Value")}
        </Typography>
      )}

      <FinancialsCard
        currency={contract?.budget?.currency}
        totalValue={contract?.budget?.totalValue || contract?.budget?.amount}
        paymentTerms={contract?.budget?.paymentTerms}
        explanation={contract?.budget?.explanation}
      />

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult =
                  aiOutput.metadata?.structuralData?.financials;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord =
                  verifications?.find(
                    (v) => v.field === "budget" && v.aiOutputId === aiOutput.id
                  ) ||
                  verifications?.find(
                    (v) =>
                      v.field === "financials" && v.aiOutputId === aiOutput.id
                  );

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Grid container justifyContent="space-between">
                        <Typography>
                          {aiOutput.processedAt
                            ? t("date.dateTime", {
                                date: new Date(aiOutput.processedAt),
                              })
                            : "No processing date"}
                        </Typography>
                      </Grid>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="budget"
                        value={currentResult}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={
                          <FinancialsCard
                            currency={
                              aiOutput?.metadata?.structuralData?.financials
                                ?.currency
                            }
                            totalValue={
                              aiOutput?.metadata?.structuralData?.financials
                                ?.totalValue ||
                              aiOutput?.metadata?.structuralData?.financials
                                ?.amount
                            }
                            paymentTerms={
                              aiOutput?.metadata?.structuralData?.financials
                                ?.paymentTerms
                            }
                            explanation={
                              aiOutput?.metadata?.structuralData?.financials
                                ?.explanation
                            }
                          />
                        }
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Grid>
  );
}

export default ContractFinancials;
