import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Fade,
  Alert,
  AlertTitle,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Tooltip,
  ButtonProps,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  Check,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close,
  Cancel,
  Message,
  AddCircleOutline,
} from "@mui/icons-material";
import {
  useAddHumanDataVerificationMutation,
  useDeleteHumanDataVerificationMutation,
  useUpdateContractMutation,
  useUpdateHumanDataVerificationMutation,
} from "../../../../redux/slices/indexApiSlice";
import { DialogMode } from "../../../../types/dialogmode";
import { HumanDataVerification } from "../../../../types/humanDataVerification";
import HumanVerificationForm from "../../../../components/forms/HumanVerificationForm";
import useAuth from "../../../../hooks/useAuth";

function HumanDataVerificationDialog({
  ...props
}: {
  open?: boolean;
  handleClose?: () => void;
  onSuccess?: () => void;
  mode: DialogMode;
  iconOnly?: boolean;
  asMenuItem?: boolean; // renders the button as a menu item
  disabled?: boolean;
  showMenuIcon?: boolean;
  initialValues?: Partial<HumanDataVerification>;
  verificationRecord?: HumanDataVerification;
  inlineConfirmation?: boolean;
  button?: ButtonProps;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [open, setOpen] = useState(props.open || false);
  const { mode, asMenuItem } = props;
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [updateContract] = useUpdateContractMutation();
  const [
    addVerification,
    {
      data: addedVerification,
      isLoading: isAdding,
      isSuccess: isAdded,
      reset: resetAdd,
      isError: addVerificationError,
      error: addVerificationErrorObject,
    },
  ] = useAddHumanDataVerificationMutation();

  const [
    updateVerification,
    {
      data: updatedVerification,
      isSuccess: isUpdated,
      reset: resetUpdate,
      isError: updateVerificationError,
      error: updateVerificationErrorObject,
    },
  ] = useUpdateHumanDataVerificationMutation();

  const [
    deleteItem,
    {
      data: deletedVerification,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
      isError: deleteError,
      error: deleteErrorObject,
    },
  ] = useDeleteHumanDataVerificationMutation();

  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      if (isAdded && addedVerification) {
        props.onSuccess && props.onSuccess();
        resetAdd();
      }

      if (isUpdated && updatedVerification) {
        props.onSuccess && props.onSuccess();

        resetUpdate();
      }

      if (isDeleted && deletedVerification) {
        resetDelete();
      }

      setSuccess(true);

      resetAndClose();
    }
  }, [
    isAdded,
    isUpdated,
    isDeleted,
    addedVerification,
    updatedVerification,
    deletedVerification,
  ]);

  const resetAndClose = () => {
    setOpen(false);

    if (props.handleClose) {
      props.handleClose();
    }

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleAddVerification = async (v: HumanDataVerification) => {
    const vToAdd: HumanDataVerification = {
      ...v,
      verifiedBy: user.displayName,
      verifiedDate: new Date().toUTCString(),
    };

    await addVerification(vToAdd);
  };

  const handleUpdateVerification = async (v: HumanDataVerification) => {
    const vToUpdate: HumanDataVerification = {
      ...v,
      verifiedBy: user.displayName,
      verifiedDate: new Date().toUTCString(),
    };

    await updateVerification(vToUpdate);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.verificationRecord) {
      await deleteItem(props.verificationRecord);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete verification")}>
              {props.iconOnly ? (
                <IconButton
                  size="small"
                  onClick={handleDeleteClick}
                  disabled={props.disabled}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setOpen(true)}
                  disabled={props.disabled}
                >
                  {t("Delete verification")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                  disabled={props.disabled}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                  disabled={props.disabled}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {props.mode === DialogMode.Add && (
        <>
          {props.button ? (
            <Button {...props.button} onClick={() => setOpen(true)} />
          ) : props.iconOnly ? (
            <IconButton
              size="large"
              color="inherit"
              onClick={() => setOpen(true)}
            >
              <Message />
            </IconButton>
          ) : (
            <Button
              variant="text"
              onClick={() => setOpen(true)}
              disabled={props.disabled}
            >
              <AddCircleOutline sx={{ mr: 2 }} /> {t("Add verification")}
            </Button>
          )}
        </>
      )}

      {props.mode === DialogMode.Edit && (
        <Box sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}>
          {asMenuItem ? (
            <MenuItem onClick={() => setOpen(true)}>
              <EditIcon />
              {t("Edit")}
            </MenuItem>
          ) : props.iconOnly ? (
            <Tooltip title={t(`Edit`)}>
              <IconButton
                size="small"
                // color="inherit"
                onClick={() => setOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              size="small"
              color="primary"
              aria-label="Edit"
              disabled={props.disabled}
              onClick={() => setOpen(true)}
            >
              {t("Edit")}
            </Button>
          )}
        </Box>
      )}

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        aria-labelledby="verification-dialog-title"
        id="verification-dialog"
        // slotProps={{
        //   paper: {
        //     component: "form",
        //     onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        //       event.preventDefault();
        //       const formData = new FormData(event.currentTarget);
        //       const formJson = Object.fromEntries((formData as any).entries());

        //       onConfirm(formJson.comments);

        //       handleClose();
        //     },
        //   },
        // }}
      >
        <DialogTitle id="verification-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid>
              {mode === DialogMode.Add
                ? props.initialValues?.isCorrect
                  ? t("AI is correct")
                  : t("AI is incorrect")
                : mode === DialogMode.Edit
                ? t("Edit verification")
                : mode === DialogMode.Delete
                ? t("Are you sure you want to delete this verification?")
                : ""}
            </Grid>
            <Grid>
              {mode !== DialogMode.Delete && (
                <IconButton size="small" onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>

        {/* {mode !== DialogMode.Delete && (
          <DialogContent>
            <TextField
              autoFocus
              required
              id="comments"
              name="comments"
              label={t("Comments")}
              defaultValue={props.verificationRecord?.comments}
              multiline
              fullWidth
              rows={8}
              sx={{ my: 2 }}
            />
          </DialogContent>
        )} */}

        {mode !== DialogMode.Delete && (
          <DialogContent>
            {(updateVerificationError ||
              addVerificationError ||
              deleteError) && (
              <Alert severity="error" sx={{ mb: 6 }}>
                <AlertTitle>{t("An error occurred!")}</AlertTitle>

                {JSON.stringify(addVerificationErrorObject)}

                {JSON.stringify(updateVerificationErrorObject)}

                {JSON.stringify(deleteErrorObject)}
              </Alert>
            )}

            <HumanVerificationForm
              mode={props.mode}
              addVerification={handleAddVerification}
              updateVerification={handleUpdateVerification}
              verification={props.verificationRecord}
              initialValues={props.initialValues}
            />
          </DialogContent>
        )}

        <DialogActions>
          {success ? (
            <Button
              onClick={() => resetAndClose()}
              color="primary"
              autoFocus
              variant="outlined"
            >
              {t("Cancel")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button onClick={() => resetAndClose()} color="primary">
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="verification-form"
                  color="primary"
                  disabled={isAdding}
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HumanDataVerificationDialog;
