import styled from "@emotion/styled";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { AssistantThread } from "../../types/chatBot";
import { convertCreatedDate } from "../../utils/string";

import ContractAvatar from "../avatars/ContractAvatar";
import VendorAvatar from "../avatars/VendorAvatar";
import { epochToDate } from "../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import { getMessage } from "./ChatMessage";

const ChatDialog = styled(ListItemButton)`
  // padding-top: ${(props) => props.theme.spacing(3)};
  // padding-bottom: ${(props) => props.theme.spacing(3)};
  // padding-left: ${(props) => props.theme.spacing(5)};
  // padding-right: ${(props) => props.theme.spacing(5)};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Main Component
type ChatHistoryItemProps = {
  item: AssistantThread;
  onClick: (thread: AssistantThread) => any;
  onChange: (thread: AssistantThread, checked: boolean) => any;
};

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
  item,
  onClick,
  onChange,
}) => {
  const { contractId, vendorId } = useParams();

  const handleListItemChecked = (e: any, checked: boolean) => {
    e.stopPropagation();

    if (onChange) {
      onChange(item, checked);
    }
  };

  return (
    <ListItem secondaryAction={<ArrowForwardIosRounded color="primary" />}>
      <ListItemButton
        // px={8}
        // py={4}
        // key={chatBotHistory.id}
        onClick={() => onClick(item)}
      >
        {/* <ListItemAvatar>
        <OwlAvatarIcon />
      </ListItemAvatar> */}
        <ListItemIcon>
          <Checkbox
            edge="start"
            // checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            // inputProps={{ "aria-labelledby": labelId }}
            onClick={(e) => e.stopPropagation()}
            onChange={handleListItemChecked}
          />
        </ListItemIcon>
        {item.messages && (
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // allow 3 lines
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {getMessage(item.messages[0])}
              </Typography>
            }
            secondary={
              item.messages[0].created_at && (
                <Typography variant="caption">
                  {convertCreatedDate(epochToDate(item.messages[0].created_at))}
                </Typography>
              )
            }
          />
        )}
        {item.contractId && item.contractId !== contractId && (
          <Chip
            sx={{ mr: 12, maxWidth: "calc(100% - 400px)" }}
            label={<ContractAvatar contractId={item.contractId} small />}
            color="info"
          />
        )}
        {item.vendorId && item.vendorId !== vendorId && (
          <Chip
            sx={{ mr: 12, maxWidth: "calc(100% - 400px)" }}
            label={<VendorAvatar vendorId={item.vendorId} small />}
            color="info"
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default ChatHistoryItem;
