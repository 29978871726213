import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Tooltip,
  Fade,
  CardActionArea,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing, SpacingProps } from "@mui/system";
import { DialogMode } from "../../../types/dialogmode";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import AskMeAnything from "../../components/AskMeAnything";
import DataGridPrompts from "../../../components/datagrids/DataGridPrompts";
import PromptDialog from "../../../components/dialogs/PromptDialog";
import DataGridContractCategories from "../../../components/datagrids/DataGridContractCategories";
import ContractCategoryDialog from "../../../components/dialogs/ContractCategoryDialog";
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ContractCategoriesList({ ...props }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Contract categories")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link
              component={NavLink}
              to="/libraries/contract-categories"
              color="inherit"
            >
              <Typography variant="h3" gutterBottom display="inline">
                {t("Contract categories")}
              </Typography>
            </Link>
          </Breadcrumbs>
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <AskMeAnything placeholder={"Ask a question about prompting"} />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <Grid container gap={3}>
            <Grid>
              <ContractCategoryDialog mode={DialogMode.Add} />
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <DataGridContractCategories view="table" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContractCategoriesList;
