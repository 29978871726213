import { useTranslation } from "react-i18next";
import { Box, Typography, Alert } from "@mui/material";
import { Info, ThumbDown, ThumbUp } from "@mui/icons-material";
import { useUpdateHumanDataVerificationMutation } from "../../../../redux/slices/indexApiSlice";
import { HumanDataVerification } from "../../../../types/humanDataVerification";
import HumanDataVerificationDialog from "./HumanDataVerificationDialog";
import { DialogMode } from "../../../../types/dialogmode";
import useAuth from "../../../../hooks/useAuth";

function VerifiedAISuggestion({
  verificationRecord,
}: {
  verificationRecord?: HumanDataVerification;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [updateVerification, { isLoading: isUpdating }] =
    useUpdateHumanDataVerificationMutation();

  if (!verificationRecord) {
    return null;
  }

  return (
    <Alert
      severity={
        verificationRecord?.isCorrect === false
          ? "error"
          : verificationRecord?.isCorrect
          ? "success"
          : "info"
      }
      variant="outlined"
      icon={
        verificationRecord?.isCorrect === false ? (
          <ThumbDown />
        ) : verificationRecord?.isCorrect ? (
          <ThumbUp />
        ) : (
          <Info />
        )
      }
      action={
        <>
          <HumanDataVerificationDialog
            mode={DialogMode.Edit}
            verificationRecord={verificationRecord}
            // onConfirm={(comments) =>
            //   updateVerification({
            //     ...verificationRecord,
            //     comments,
            //     verifiedBy: user.displayName,
            //     verifiedDate: new Date().toUTCString(),
            //   })
            // }
            iconOnly
            showMenuIcon
            disabled={isUpdating}
          />
          <HumanDataVerificationDialog
            mode={DialogMode.Delete}
            verificationRecord={verificationRecord}
            iconOnly
            showMenuIcon
            disabled={isUpdating}
          />
        </>
      }
    >
      {verificationRecord?.comments && (
        <Typography>
          <i>{verificationRecord.comments}</i>
        </Typography>
      )}

      {verificationRecord?.verifiedBy && (
        <Box mt={2}>
          <Typography
            variant="caption"
            color="textDisabled"
            alignItems={"center"}
            display={"flex"}
            sx={{ mt: 1 }}
          >
            {/* <HowToReg fontSize="small" sx={{ mr: 1 }} /> */}

            {t("Verified by", {
              name: verificationRecord.verifiedBy,
              date: new Date(verificationRecord.verifiedDate),
            })}
          </Typography>
        </Box>
      )}
    </Alert>
  );
}

export default VerifiedAISuggestion;
