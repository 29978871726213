import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider as MuiDivider,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DialogMode } from "../../../../types/dialogmode";
import { Paragraph } from "../../../../types/aiOutput";
import { TermsAndConditionsArticle } from "../../../../types/termsAndConditionsArticle";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
enum TabIndex {
  AI_ASSISTANT,
  ATTACHMENTS,
  ROLES,
  NOTES,
  AI_READ,
  SIGNEES,
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flexStart",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
  tabIndex: number;
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  border: "1px solid gray",
  // color: "rgba(255, 255, 255, 0.7)",

  "&.Mui-selected": {
    // color: "#fff",
    backgroundColor: "background.paper",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const ArticleAccordion = ({
  mode,
  article,
  selectParagraph,
  isExpanded,
}: {
  mode: DialogMode;
  article: TermsAndConditionsArticle;
  selectParagraph: (paragraph?: Paragraph) => void;
  isExpanded: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Accordion key={`accordion-${article.id}`} id={`panel-${article.id}-block`}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${article.id}`}
        id={`panel-${article.id}-header`}
      >
        <ListItem disablePadding disableGutters>
          <ListItemIcon>
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: (theme) => theme.palette.grey[900],
              }}
            >
              {article.articleNumber}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={article?.title ? t(article.title) : t("Unknown")}
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        {article && <Box>{article.body}</Box>}
      </AccordionDetails>
    </Accordion>
  );
};

export default ArticleAccordion;
