import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  DocumentLoadEvent,
  OpenFile,
  PageLayout,
  ScrollMode,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from "@react-pdf-viewer/page-navigation";
import { printPlugin, RenderPrintProps } from "@react-pdf-viewer/print";
import {
  RenderShowSearchPopoverProps,
  searchPlugin,
} from "@react-pdf-viewer/search";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import {
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import disableScrollPlugin from "./disableScrollPlugin";
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from "@react-pdf-viewer/default-layout";

import _ from "underscore";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

import type { RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import * as pdfjsLib from "pdfjs-dist";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import { Rnd } from "react-rnd";

// ** MUI Imports
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Print as PrintIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Dialog,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme as muiTheme,
  Badge,
  Avatar,
  AvatarGroup,
  Fab,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha } from "@mui/system";

import { Edit } from "react-feather";
import { useTranslation } from "react-i18next";

import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fileExists } from "../../redux/slices/filesSlice";
import { RootState } from "../../redux/store";
import { IDocument } from "../../types/document";
import { ISignature, ISignee } from "../../types/signature";

import packageJson from "../../../package.json";
import AIDocumentSignature from "../../pages/main/contracts/components/AIDocumentSignature";
import { IPrompt } from "../../types/prompt";
import SignatureFields from "./SignatureFields";

import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";
import useReadingIndicatorPlugin from "./readingIndicatorPlugin";
import { rgba } from "polished";
import { useGetPromptsQuery } from "../../redux/slices/indexApiSlice";
import {
  ContractExtractionResult,
  Page,
  Paragraph,
} from "../../types/aiOutput";
import { Area } from "../../types/area";

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

type HighlightData = {
  pageNumber: number;
  polygon: number[];
  content: string;
};

const getAreaFromPolygon = (
  topLeftX: number,
  topLeftY: number,
  topRightX: number,
  topRightY: number,
  bottomRightX: number,
  bottomRightY: number,
  bottomLeftX: number,
  bottomLeftY: number,
  viewPortWidth: number,
  viewPortHeight: number,
  index: number,
  areaId: string,
  prompt?: IPrompt,
  page?: Page,
  infoFound?: boolean
): Area => {
  const unit = page?.unit || "inch";
  const pageHeight = page?.height || 11;
  const pageWidth = page?.width || 8.5;

  const relativeHeight = (bottomLeftY - topLeftY) / pageHeight;
  const heightInPx = relativeHeight * viewPortHeight;

  const relativeTop = (topLeftY / pageHeight) * 100;

  const relativeWidth = (topRightX - topLeftX) / pageWidth;
  const widthInPx = relativeWidth * viewPortWidth;

  const paddingLeft = 0.1; // inch
  const relativeLeft = ((topLeftX - paddingLeft) / pageWidth) * 100;

  const TwoPxInPerc = (2 / viewPortHeight) * 100;
  const data: Area = {
    pageIndex: index - 1,
    top: relativeTop - TwoPxInPerc,
    left: relativeLeft, // 0
    height: heightInPx + 4,
    width: widthInPx + 15, // 30
    promptId: prompt?.id || "",
    paragraphId: areaId,
    aiOutputId: "",
    key: areaId,
    infoFound: infoFound || false,
  };

  // data.top -= 1;
  // data.height += 2; // add a little margin

  return data;
};

const getParagraphAreas = (
  paragraphs: Paragraph[],
  pages: Page[],
  allViewPorts: any,
  prompt: IPrompt,
  infoFound: boolean,
  isInSection: boolean
): Area[] => {
  const newAreas: Area[] = [];

  paragraphs.forEach((paragraph) => {
    if (paragraph.boundingRegions.length === 0) {
      return;
    }

    // if (
    //   paragraph.role === "pageFooter" ||
    //   paragraph.role === "pageHeader" ||
    //   paragraph.role === "sectionHeading"
    // ) {
    //   continue;
    // }

    const page = pages.find(
      (page) => page.pageNumber === paragraph.boundingRegions[0].pageNumber
    );

    paragraph.boundingRegions.forEach((region) => {
      // const page = await pdfjsLib
      //   .getDocument(fileContent)
      //   .promise.then((pdf) => pdf.getPage(region.pageNumber));

      // const viewPort = page.getViewport({ scale: 1 });

      const viewPort = allViewPorts[region.pageNumber - 1];

      if (!viewPort) {
        return;
      }

      const area = getAreaFromPolygon(
        region.polygon[0],
        region.polygon[1],
        region.polygon[2],
        region.polygon[3],
        region.polygon[4],
        region.polygon[5],
        region.polygon[6],
        region.polygon[7],
        viewPort.width,
        viewPort.height,
        region.pageNumber,
        paragraph.id,
        prompt,
        page,
        infoFound || false
      );

      newAreas.push(area);
    });

    return newAreas;
  });

  return newAreas;
};

const getSectionBoundingRegion = (page: Page, paragraphs: Paragraph[]) => {
  const minTopLeftX = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.min(acc, region.polygon[0]);
  }, 1000);

  const minTopLeftY = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.min(acc, region.polygon[1]);
  }, 1000);

  const maxTopRightX = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.max(acc, region.polygon[2]);
  }, 0);

  const minTopRightY = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.min(acc, region.polygon[3]);
  }, 1000);

  const maxBottomRightX = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.max(acc, region.polygon[4]);
  }, 0);

  const maxBottomRightY = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.max(acc, region.polygon[5]);
  }, 0);

  const minBottomLeftX = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.min(acc, region.polygon[6]);
  }, 1000);

  const maxBottomLeftY = paragraphs.reduce((acc, p) => {
    const region = p.boundingRegions[0];

    if (region.pageNumber !== page.pageNumber) {
      return acc;
    }

    return Math.max(acc, region.polygon[7]);
  }, 0);

  return {
    minTopLeftX,
    minTopLeftY,
    maxTopRightX,
    minTopRightY,
    maxBottomRightX,
    maxBottomRightY,
    minBottomLeftX,
    maxBottomLeftY,
  };
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const PromptTooltip = styled(
  ({ className, color, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color, // theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    padding: 8,
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";

const SIGN_AREA_WIDTH = 180;
const SIGN_AREA_HEIGHT = 75;

const StyledSignArea = styled(Box)`
  position: relative;
  width: ${SIGN_AREA_WIDTH}px;
  height: ${SIGN_AREA_HEIGHT}px;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
const SignIcon = styled(Box)`
  position: absolute;
  top: -10px;
  left: 10px;
`;

const SignatureImg = styled("img")`
  width: 150px;
  height: auto;
  opacity: 1;
  position: absolute;
  zindex: -1;
`;

const ElevatedPaper = styled(Paper)({
  boxShadow: elevation3,
  margin: 4,
});

const HoverBox = styled(Box)`
  // opacity: 0;
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
  z-index: 200;
  // background-color: ${(props) => props.theme.palette.background.default};

  // &:hover {
  //   opacity: 1;
  // }
`;

const SignArea: React.FC<{
  signer?: ISignee;
  signature: ISignature;
  signable?: boolean;
  onOpenSignaturePad: (email: string) => void;
  onMoveToFirstPage: (email: string) => void;
  onMoveToPrevPage: (email: string) => void;
  onMoveToNextPage: (email: string) => void;
  onMoveToLastPage: (email: string) => void;
  numPages: number;
}> = ({
  signer,
  signature: sig,
  signable,
  onOpenSignaturePad,
  onMoveToFirstPage,
  onMoveToNextPage,
  onMoveToPrevPage,
  onMoveToLastPage,
  numPages,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <StyledSignArea
        sx={{
          borderColor: sig.email === signer?.email ? "#22A121" : "#FDA018",
          pointerEvents:
            sig.email !== signer?.email && signable ? "none" : "all",
          color: "black",
          cursor:
            sig.draggable && !sig.isSigned
              ? "grab"
              : signable && sig.email === signer?.email && !sig.signedDate
              ? "pointer"
              : "default",
          "&:active": {
            cursor: sig.draggable && !sig.isSigned ? "grab" : "default",
          },
          "&:hover": {
            backgroundColor:
              sig.email === signer?.email ? grey[100] : "initial",
          },
        }}
        onClick={() =>
          signable && sig.email === signer?.email && !sig.signedDate
            ? onOpenSignaturePad(sig.email)
            : null
        }
      >
        <SignIcon
          sx={{
            color: sig.email === signer?.email ? "#22A121" : "#FDA018",
          }}
        >
          <Edit />
        </SignIcon>
        <Typography>{t("Sign for")}</Typography>
        <Typography fontWeight="bold">{sig.displayName}</Typography>
        {signable && sig.email === signer?.email && !sig.signedDate && (
          <Typography variant="caption">({t("click here")})</Typography>
        )}
      </StyledSignArea>
      {sig.draggable && !sig.isSigned && numPages > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: -1.5 }}>
          <Tooltip title={t("Move to first page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToFirstPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <FirstPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to previous page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToPrevPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateBefore sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to next page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToNextPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateNext sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to last page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToLastPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <LastPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

type BoundingRegion = {
  pageNumber: number;
  polygon: number[];
};

export interface IReactPDFViewerProps {
  file?: File;
  fileUrl?: string;
  document?: IDocument;
  arrayBuffer?: ArrayBuffer;
  defaultScale?: number;
  viewMode?: "withMenu" | "pageOnly";
  signable?: boolean;
  signer?: ISignee;
  signatures?: Array<ISignature>;
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  toggleSignaturePad?: boolean;
  onSuccess?: () => void;
  paging?: boolean;

  aiOutputs?: ContractExtractionResult[];

  prompt?: IPrompt; // the prompt to highlight
  selectedArea?: Area;
  setSelectedArea: (area?: Area) => void;
  selectedAreaPage?: number;
}
function ReactPDFViewer({ ...props }: IReactPDFViewerProps) {
  const {
    aiOutputs,
    signer,
    signable,
    signatures,
    setSignatures,
    setSignatureSizePercentValue,
    onSaveSignature,
    toggleSignaturePad,
    selectedArea,
    setSelectedArea,
    selectedAreaPage,
  } = props;

  const { t } = useTranslation();
  const { theme } = useTheme();

  const materialTheme = muiTheme();

  const { contractId } = useParams();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [paperHovered, setPaperHovered] = useState(false);
  const [ready, setReady] = useState(false);

  const [fileContent, setFileContent] = useState<Uint8Array | string>(
    new Uint8Array([])
  );

  const [showSignaturePad, setSignaturePad] = useState(false);
  const [documentSize, setDocumentSize] = useState({ width: 0, height: 0 });
  const documentRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  // check if the file is already in the store
  const fileContentExists = useSelector<RootState, boolean | undefined>(
    (state) =>
      props.document ? fileExists(props.document.id || "")(state) : undefined
  );

  const fileBuffer = useSelector<RootState, ArrayBuffer>(
    (state) => state.files.files[props.document?.id || ""] || "",
    _.isEqual
  );

  useEffect(() => {
    if (props.document?.id && !props.file) {
      // the id changed, reset the file content
      //   setFileContent(undefined);
      // setReady(false);
      // reset the highlights
      setHighlightAreas([]);
    }
  }, [props.document?.id]);

  useEffect(() => {
    if (props.document?.id && !props.file && !props.fileUrl && fileBuffer) {
      if ((fileBuffer as any)?.data) {
        setFileContent(new Uint8Array((fileBuffer as any).data));
      }
    } else {
      if (props.file) {
        let url = URL.createObjectURL(props.file);
        setFileContent(url);
      } else if (props.fileUrl) {
        setFileContent(props.fileUrl);
      }
    }
  }, [
    contractId,
    props.file,
    props.document?.id,
    fileContentExists,
    props.fileUrl,
  ]);

  useEffect(() => {
    if (typeof toggleSignaturePad === "boolean") {
      setSignaturePad((prev) => !prev);
    }
  }, [toggleSignaturePad]);

  useEffect(() => {
    if (documentRef.current) {
      setDocumentSize({
        width: documentRef.current.clientWidth,
        height: documentRef.current.clientHeight,
      });
    }
  }, [
    documentRef,
    documentRef.current,
    documentRef.current?.clientWidth,
    documentRef.current?.clientHeight,
  ]);

  // useEffect(() => {
  //   if (selectedAreaPage && selectedAreaPage > 0 && ready) {
  //     jumpToPage(selectedAreaPage);

  //     // setTimeout(() => {
  //     //   jumpToPage(selectedAreaPage);
  //     // }, 1000);
  //   }
  // }, [selectedAreaPage]);

  // useEffect(() => {fileContentfileContent
  //   window.scrollTo(0, scrollPosition);

  //   setTurningPage(true);
  //   setTimeout(() => {
  //     setTurningPage(false);
  //   }, 1500);
  // }, [pageNumber]);

  useEffect(() => {
    if (fileContent || fileBuffer) {
      // fetchTextAreas();

      if (ready) {
        fetchTextAreasFromPolygon();
      }
    }
  }, [fileContent, fileBuffer, ready, props.prompt?.id, props.selectedArea]);

  // useEffect(() => {
  //   if (props.prompt) {
  //     const elements = document.querySelectorAll(
  //       '[id^="highlight-ref-' + props.prompt?.id + '"]'
  //     );
  //     if (elements.length > 0) {
  //       elements[0].scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }
  //   }
  // }, [props.prompt]);

  const onDocumentLoadSuccess = (args: DocumentLoadEvent) => {
    console.log({ numPages: args?.doc?.numPages });
    setNumPages(args?.doc?.numPages);

    if (props.onSuccess) {
      props.onSuccess();
    }

    setReady(true);
    // setDocumentLoaded(true);
    // setPageNumber(pageNumber);
  };

  const handleOpenSignaturePad = (email: string) => {
    setSignaturePad(true);
  };
  const handleCloseSignaturePad = () => {
    setSignaturePad(false);
  };

  const handleSave = (signatureUrl: string) => {
    if (setSignatures) {
      setSignatures((prev) => {
        const newSignatures = prev.map((sig) => {
          if (sig.email === signer?.email) {
            return {
              ...sig,
              isApproved: true,
              signatureUrl,
              signedDate: new Date().toISOString(),
            };
          } else {
            return { ...sig };
          }
        });

        return newSignatures;
      });
    }

    if (onSaveSignature) {
      onSaveSignature();
    }

    handleCloseSignaturePad();
  };

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      return props.document?.name || "document.pdf";
    },
  });

  const { Download } = getFilePluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut, CurrentScale, ZoomPopover } = zoomPluginInstance;

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const {
    CurrentPageInput,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
    jumpToPage,
    CurrentPageLabel,
  } = pageNavigationPluginInstance;

  const searchPluginInstance = searchPlugin({
    // Highlight "" words initially
    keyword: [
      "",
      {
        keyword: "",
        matchCase: true,
      },
    ],
  });
  const { ShowSearchPopover } = searchPluginInstance;

  const disableScrollPluginInstance = disableScrollPlugin();

  const pageLayout: PageLayout = {
    buildPageStyles: () => ({
      marginTop: 100,
    }),

    transformSize: ({ size }) => ({
      height: size.height + 30,
      width: size.width + 30,
    }),
  };

  // Highlights Blocks
  const [highlightAreas, setHighlightAreas] = useState<Area[]>([]);

  // const findTextItem = (
  //   textItems: TextItem[],
  //   words: string
  // ): TextItem | undefined => {
  //   // find the element in the textItems array
  //   let foundItem: TextItem | undefined = undefined;

  //   const pageContent = textItems.map((item) => item.str || "").join("");

  //   if (pageContent.includes(words)) {
  //     const textItem = textItems.find((item) => item.str.includes(words));
  //     // return textItem;

  //     // build an array with every character and text item index
  //     const textItemsWithIndex = textItems.reduce((acc: any, item, index) => {
  //       return [...acc, ...item.str.split("").map((char) => ({ char, index }))];
  //     }, []);

  //     // find the characters of the words
  //     const wordsChars = words.split("");

  //     textItemsWithIndex.forEach((item: any, i: number) => {
  //       if (foundItem) {
  //         return;
  //       }

  //       // check if the first character of the words is in the text
  //       if (item.char === wordsChars[0]) {
  //         // check if the next characters are in the text
  //         const found = wordsChars.every((char, j) => {
  //           return textItemsWithIndex[i + j]?.char === char;
  //         });

  //         if (found) {
  //           const textItem = textItems[textItemsWithIndex[i].index];

  //           foundItem = textItem;
  //         }
  //       }
  //     });
  //   }

  //   if (foundItem) {
  //     return foundItem;
  //   }

  //   const textItem = textItems.find((item, i) => {
  //     // check if the first two words are in the text
  //     if (item.str.includes(words)) {
  //       return item;
  //     }

  //     const parts = words.split(" ");

  //     // one word can also be in the previous item
  //     if (i > 0) {
  //       const prevItem = textItems[i - 1];
  //       if (prevItem.str.includes(parts[0]) && item.str.includes(parts[1])) {
  //         return prevItem;
  //       }
  //     }

  //     // one word can also be in the next item
  //     if (i < textItems.length - 1) {
  //       const nextItem = textItems[i + 1];
  //       if (item.str.includes(parts[0]) && nextItem.str.includes(parts[1])) {
  //         return nextItem;
  //       }
  //     }

  //     const parts2 = words.split(".");

  //     // one word can also be in the previous item
  //     if (i > 0) {
  //       const prevItem = textItems[i - 1];
  //       if (prevItem.str.includes(parts2[0]) && item.str.includes(parts2[1])) {
  //         return prevItem;
  //       }
  //     }

  //     // one word can also be in the next item
  //     if (i < textItems.length - 1) {
  //       const nextItem = textItems[i + 1];
  //       if (item.str.includes(parts2[0]) && nextItem.str.includes(parts2[1])) {
  //         return nextItem;
  //       }
  //     }

  //     return null;
  //   });

  //   return textItem;
  // };

  const getPositionFromTransform = (
    textItem: TextItem
  ): {
    x: number;
    y: number;
  } => {
    const { transform } = textItem;
    if (!transform || transform.length !== 6) {
      throw new Error("Invalid transform format");
    }

    const [a, b, c, d, tx, ty] = transform;
    return { x: tx, y: ty };
  };

  // const getArea = (
  //   itemStart: TextItem,
  //   itemEnd: TextItem,
  //   viewPortWidth: number,
  //   viewPortHeight: number,
  //   index: number,
  //   localText: ILocationInText
  // ): Area => {
  //   const { x: x1, y: y1 } = getPositionFromTransform(itemStart);
  //   const { x: x2, y: y2 } = getPositionFromTransform(itemEnd);

  //   let data: Area = {
  //     pageIndex: index - 1,
  //     top:
  //       ((viewPortHeight - y1 - Math.max(itemStart.height, itemEnd.height)) /
  //         viewPortHeight) *
  //       100,
  //     left: 0,
  //     height:
  //       ((Math.abs(y2 - y1) + Math.max(itemStart.height, itemEnd.height)) /
  //         viewPortHeight) *
  //       100,
  //     width: 100,
  //     key: localText.key,
  //   };

  //   // data.top -= 1;
  //   data.height += 2; // add a little margin

  //   return data;
  // };

  const fetchTextAreasFromPolygon = useCallback(async () => {
    const areas: Area[] = [];

    const firstPage = await pdfjsLib
      .getDocument(fileContent)
      .promise.then((pdf) => pdf.getPage(1));

    // get all pages
    const allPages = await pdfjsLib
      .getDocument(fileContent)
      .promise.then((pdf) =>
        Promise.all(
          Array.from(new Array(pdf.numPages), (_, i) => pdf.getPage(i + 1))
        )
      );

    const allViewPorts = allPages.map((page) => page.getViewport({ scale }));

    if (aiOutputs) {
      for (const aiOutput of aiOutputs) {
        if (aiOutput.documentId !== props.document?.id) {
          continue;
        }

        // only allow the aiOutput for the selected area
        if (
          selectedArea?.aiOutputId &&
          aiOutput.id !== selectedArea.aiOutputId
        ) {
          continue;
        }

        // if the selected area has no aiOutputId, we display the latest output for the area's promptID
        if (selectedArea?.promptId && !selectedArea?.aiOutputId) {
          const promptResults = aiOutputs.filter((a) =>
            a.prompts?.some((p) => p.promptID === selectedArea.promptId)
          );

          if (aiOutput.id !== promptResults[0].id) {
            continue;
          }
        }

        if (aiOutput?.prompts && aiOutput.prompts.length > 0) {
          const areasInCurrentPrompt = aiOutput.prompts.filter(
            (p) =>
              (!props.selectedArea?.promptId && !props.prompt?.id) ||
              p.promptID === props.selectedArea?.promptId ||
              p.promptID === props.prompt?.id
          );

          for (const result of aiOutput.prompts) {
            // if (result.promptKey !== "Vertrouwelijkheid") {
            //   continue;
            // }

            if (!selectedArea && result.promptID) {
              // if no area is selected, we display the latest output for the prompt

              const promptResults = aiOutputs.filter((a) =>
                a.prompts?.some((p) => p.promptID === result.promptID)
              );

              if (aiOutput.id !== promptResults[0].id) {
                continue;
              }
            }

            // check if it regards the latest output for the prompt
            const promptResults = aiOutputs.filter((a) =>
              a.prompts?.some((p) => p.promptID === result.promptID)
            );

            const prompt = prompts?.find(
              (p) => p.id === result.promptID && result.promptID
            );

            if (!prompt) {
              continue;
            }

            // highlight each paragraph in the section

            result.relevantSections?.forEach((section) => {
              if (!section.paragraphs) {
                return;
              }

              // draw an area for article
              const firstParagraph = section.paragraphs[0];

              const sectionPages = result.relevantPages.filter((page) =>
                section.paragraphs.some(
                  (p) => p.boundingRegions[0].pageNumber === page.pageNumber
                )
              );

              // draw an area on each relevant page
              for (const page of sectionPages) {
                const viewPort = allViewPorts[page.pageNumber - 1];

                if (!viewPort) {
                  continue;
                }

                const areaBoundingRegion = getSectionBoundingRegion(
                  page,
                  section.paragraphs
                );

                const area = getAreaFromPolygon(
                  areaBoundingRegion.minTopLeftX,
                  areaBoundingRegion.minTopLeftY,
                  areaBoundingRegion.maxTopRightX,
                  areaBoundingRegion.minTopRightY,
                  areaBoundingRegion.maxBottomRightX,
                  areaBoundingRegion.maxBottomRightY,
                  areaBoundingRegion.minBottomLeftX,
                  areaBoundingRegion.maxBottomLeftY,
                  viewPort.width,
                  viewPort.height,
                  page.pageNumber,
                  section.id,
                  prompt,
                  page,
                  true
                );

                areas.push(area);
              }

              // also draw the most relevant paragraphs
              const mostRelevantParagraphsInSection = section.paragraphs.filter(
                (p) => result.relevantParagraphs.some((rp) => rp.id === p.id)
              );

              const paragraphAreas = getParagraphAreas(
                mostRelevantParagraphsInSection, //   section.paragraphs,
                result.relevantPages,
                allViewPorts,
                prompt,
                result.extractionResult.infoFound,
                true
              );

              areas.push(...paragraphAreas);
            });

            if (
              !result.relevantSections ||
              result.relevantSections.length === 0
            ) {
              const paragraphAreas = getParagraphAreas(
                result.relevantParagraphs,
                result.relevantPages,
                allViewPorts,
                prompt,
                result.extractionResult.infoFound,
                false
              );

              areas.push(...paragraphAreas);
            }
          }
        } else if (aiOutput.articles && aiOutput.articles.length > 0) {
          for (const article of aiOutput.articles) {
            if (!article.relevantParagraphs) {
              continue;
            }

            // draw an area for article
            const firstParagraph = article.relevantParagraphs[0];

            if (!article.relevantPages) {
              continue;
            }

            // draw an area on each relevant page
            for (const page of article.relevantPages) {
              const viewPort = allViewPorts[page.pageNumber - 1];

              if (!viewPort) {
                continue;
              }

              const areaBoundingRegion = getSectionBoundingRegion(
                page,
                article.relevantParagraphs
              );

              const area = getAreaFromPolygon(
                areaBoundingRegion.minTopLeftX,
                areaBoundingRegion.minTopLeftY,
                areaBoundingRegion.maxTopRightX,
                areaBoundingRegion.minTopRightY,
                areaBoundingRegion.maxBottomRightX,
                areaBoundingRegion.maxBottomRightY,
                areaBoundingRegion.minBottomLeftX,
                areaBoundingRegion.maxBottomLeftY,
                viewPort.width,
                viewPort.height,
                page.pageNumber,
                firstParagraph.id,
                undefined,
                page,
                true
              );

              areas.push(area);
            }
          }
        }
      }
    }

    setHighlightAreas(areas);
  }, [
    fileContent,
    ready,
    aiOutputs,
    prompts,
    props.selectedArea,
    props.prompt,
  ]);

  // Handle clicked highlight
  const handleClick = (area: Area) => {
    const firstPromptOfParagraph = highlightAreas.find(
      (a) => a.paragraphId === area.paragraphId
    );

    const promptId =
      area.promptId || props.prompt?.id || firstPromptOfParagraph?.promptId;

    if (
      (area.paragraphId === selectedArea?.paragraphId &&
        area.promptId === "") ||
      (area.paragraphId === selectedArea?.paragraphId &&
        area.promptId === selectedArea?.promptId)
    ) {
      // the selected area is clicked, therefore unselect it
      setSelectedArea(undefined);
    } else if (promptId !== props.prompt?.id) {
      setSelectedArea({ ...area, promptId: promptId || "" });
    } else {
      // check if the selected paragraph exists in the current prompt
      const areaToSelect = highlightAreas.find(
        (a) =>
          a.paragraphId === area?.paragraphId && a.promptId === props.prompt?.id
      );

      if (areaToSelect) {
        setSelectedArea(areaToSelect);
      } else {
        setSelectedArea({ ...area, promptId: promptId || "" });
      }
    }
  };

  const renderBoundingRegionHighlights = (
    renderHighlightsProps: RenderHighlightsProps
  ) => {
    // every paragraph needs to be highlighted just once
    // if a paragraph is relevant to multiple prompts, we display navigation arrows in the tooltip

    const paragraphsToHighlight: {
      id: string;
      areas: Area[];
    }[] = [];

    highlightAreas
      .filter((area) => area.pageIndex === renderHighlightsProps.pageIndex)
      .forEach((area) => {
        const paragraph = paragraphsToHighlight.find((p) => p.id === area.key);

        if (paragraph) {
          paragraph.areas.push(area);
        } else {
          paragraphsToHighlight.push({ id: area.paragraphId, areas: [area] });
        }
      });

    return (
      <div>
        {paragraphsToHighlight.map((paragraph, i) => {
          const firstArea = paragraph.areas[0];

          // the area key is the paragraph id
          // const relevantResults = aiOutput?.prompts?.filter(
          //   (result) =>
          //     result.promptId === firstArea.promptId &&
          //     result.relevantParagraphs.some((p) => p.id === paragraph.id)
          // );

          // const infoFound = relevantResults?.some(
          //   (result) => result.extractionResult.infoFound
          // );

          const infoFound = paragraph.areas.some((area) => area.infoFound);

          // if a prompt is expanded, and the paragraph is not relevant to the prompt, we don't highlight it
          const isNotForCurrentPrompt =
            props.selectedArea?.promptId &&
            paragraph.areas.every(
              (area) => area.promptId !== props.selectedArea?.promptId
            )
              ? true
              : false;

          const isForCurrentPrompt = !isNotForCurrentPrompt;

          // if the paragraph is not relevant to any prompt, we don't highlight it, except if it belongs to the expanded prompt
          // if (!infoFound && !isInCurrentPrompt) {
          //   return null;
          // }

          const promptLabels = paragraph.areas
            .map((area) => {
              const prompt = prompts?.find((p) => p.id === area.promptId);

              return prompt?.title ? t(prompt.title) : area.key;
            })
            .join(", ");

          const isParagraphSelected =
            selectedArea?.paragraphId &&
            paragraph.id === selectedArea?.paragraphId;

          const baseColor = infoFound
            ? materialTheme.palette.success.main
            : materialTheme.palette.warning.main;

          // get the scale of the page

          return (
            <PromptTooltip
              title={promptLabels}
              // title={
              //   <div>
              //     {paragraph.areas.map((area, i) => {
              //       const prompt = prompts?.find((p) => p.id === area.promptId);

              //       const extractionResult = relevantResults?.find(
              //         (result) =>
              //           result.promptId === prompt?.id &&
              //           result.relevantParagraphs.some((p) => p.id === area.key)
              //       )?.extractionResult;

              //       return (
              //         <div key={i}>
              //           paragraph id: {area.paragraphId}
              //           <br />
              //           prompt id: {area.promptId}
              //           <Typography key={i}>
              //             {prompt?.title ? t(prompt.title) : area.key}
              //           </Typography>
              //           <p>{JSON.stringify(extractionResult)}</p>
              //         </div>
              //       );
              //     })}
              //   </div>
              // }
              placement="left-start"
              arrow
              // open
              color={baseColor}
            >
              <div
                key={i}
                id={"highlight-ref-" + paragraph.id}
                className="highlight-area"
                onClick={() =>
                  handleClick({
                    ...firstArea,
                    promptId: "", // because we don't know which prompt to select
                  })
                }
                style={Object.assign(
                  {},
                  renderHighlightsProps.getCssProperties(
                    firstArea,
                    renderHighlightsProps.rotation
                  ),
                  {
                    visibility: isForCurrentPrompt ? "visible" : "hidden",
                    background: rgba(
                      baseColor,
                      isParagraphSelected ? 0.5 : 0.1
                    ),
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: isParagraphSelected
                      ? baseColor
                      : isForCurrentPrompt
                      ? rgba(baseColor, 0.6)
                      : rgba(baseColor, 0.1),
                    // borderRadius: 12,
                    // opacity: 0.1,
                    zIndex: materialTheme.zIndex.modal + 1,
                    cursor: "pointer",
                    // marginTop: -6,
                    // padding: 15,
                    // marginLeft: 10,
                    // marginRight: 10,
                    height: firstArea.height * scale,
                    width: firstArea.width * scale,
                  }
                )}
              >
                <Badge
                  // badgeContent={paragraph.areas.length}
                  // badgeContent={promptLabels}
                  badgeContent={
                    <AvatarGroup
                      max={5}
                      slotProps={{
                        surplus: {
                          sx: {
                            bgcolor: alpha(baseColor, 0.5),
                            width: 24,
                            height: 24,
                            fontSize: 12,
                          },
                        },
                      }}
                    >
                      {paragraph.areas?.map((area) => {
                        const prompt = prompts?.find(
                          (p) => p.id === area.promptId
                        );

                        const isSelected =
                          (selectedArea?.paragraphId &&
                            area.promptId === selectedArea?.promptId &&
                            area.paragraphId === selectedArea?.paragraphId) ||
                          (selectedArea?.promptId &&
                            area.promptId === selectedArea?.promptId);

                        return (
                          <Tooltip
                            title={t(prompt?.title || "")}
                            placement="top"
                          >
                            <Avatar
                              sx={{
                                bgcolor: alpha(baseColor, isSelected ? 1 : 0.5),
                                width: 24,
                                height: 24,
                                zIndex: isSelected ? 1300 : undefined,
                                fontSize: 12,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClick(area);
                              }}
                            >
                              {prompt?.title
                                ? t(prompt.title)?.substring(0, 1)
                                : ""}
                            </Avatar>
                          </Tooltip>
                        );
                      })}
                    </AvatarGroup>
                  }
                  // variant="dot"
                  sx={{ position: "unset" }}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                />
              </div>
            </PromptTooltip>
          );
        })}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights: renderBoundingRegionHighlights,
    trigger: Trigger.TextSelection,
  });

  const readingIndicatorPluginInstance = useReadingIndicatorPlugin();
  const { ReadingIndicator } = readingIndicatorPluginInstance;

  const renderToolbar = React.useCallback(
    (Toolbar: (toolbarProps: ToolbarProps) => React.ReactElement) => (
      <>
        <Toolbar />
        <div
          style={{
            bottom: "-0.25rem",
            position: "absolute",
            left: 0,
            // Take the full width of toolbar
            width: "100%",
          }}
        >
          <ReadingIndicator />
        </div>
      </>
    ),
    []
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    setInitialTab: (doc) => Promise.resolve(0),
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],

    // sidebarTabs: (defaultTabs: SidebarTab[]) => {
    //   const newSiteBarTab: SidebarTab = {
    //     icon: <FileDownload />,
    //     title: t("Download"),
    //     content: <div>{props.AISideBar}</div>,
    //   };

    //   return [...defaultTabs, newSiteBarTab];
    // },
  });

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
    >
      <div
        className="rpv-core__viewer"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <Box
          id="canvas-signature-box"
          style={{
            position: "relative",
            width: "100%",
          }}
          // className="[&_.rpv-core__page-layer]:w-full"
          ref={documentRef}
          sx={{
            "& .rpv-core__inner-page": {
              // backgroundColor: "transparent",
            },
            "& .rpv-core__inner-container": {
              // height: "50vh",
            },
            // set a variable
            "--rpv-default-layout__toolbar-background-color": (props) =>
              props.palette.background.default,
            "--rpv-default-layout__sidebar-headers-background-color": (props) =>
              props.palette.background.default,
            "& .rpv-core__popover-body": {
              top: "-150px",
            },
          }}
        >
          {ready && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 3,
                inset: 0,
                pointerEvents: "none",
              }}
            >
              <SignatureFields
                signatures={signatures}
                documentRef={documentRef}
                documentSize={documentSize}
                handleOpenSignaturePad={handleOpenSignaturePad}
                numPages={numPages}
                setSignatures={setSignatures}
                signable={signable}
                signer={signer}
                onSaveSignature={onSaveSignature}
                setSignatureSizePercentValue={setSignatureSizePercentValue}
              />
            </Box>
          )}

          <Rnd
            style={{
              zIndex: materialTheme.zIndex.fab - 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            bounds="parent"
            // default align in center
            default={{
              x: 20,
              y: 20,
              width: 425,
              height: 65,
            }}
            // disable resizing
            enableResizing={{
              bottom: false,
              bottomLeft: false,
              bottomRight: false,
              left: false,
              right: false,
              top: false,
              topLeft: false,
              topRight: false,
            }}
            onDrag={(e, d) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Fab
              id="react-pdf-viewer-floating-toolbar"
              variant="extended"
              sx={{
                backgroundColor: "background.default",
                // position: "absolute",
                // top: 30,
                // width: 440,
                // // align it in the center
                // left: "calc(50% - 220px)",

                "&:hover": {
                  backgroundColor: "background.default",
                },
              }}
            >
              <Grid
                container
                sx={{
                  alignItems: "center",
                  // "& .rpv-core__popover-body": {
                  //   zIndex: 999999,
                  //   position: "absolute",
                  // },
                }}
              >
                <ShowSearchPopover>
                  {(props: RenderShowSearchPopoverProps) => (
                    <IconButton
                      onClick={props.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </ShowSearchPopover>
                {/* <GoToPreviousPage>
                  {(p: RenderGoToPageProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                  )}
                </GoToPreviousPage> */}
                {/* <CurrentPageInput /> */}
                <CurrentPageLabel>
                  {(p: RenderCurrentPageLabelProps) => (
                    <Typography>
                      {`${p.currentPage + 1}/${p.numberOfPages}`}
                    </Typography>
                  )}
                </CurrentPageLabel>

                {/* <GoToNextPage>
                  {(p: RenderGoToPageProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  )}
                </GoToNextPage> */}
                <ZoomOut>
                  {(p: RenderZoomOutProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <ZoomOutIcon />
                    </IconButton>
                  )}
                </ZoomOut>
                {/* <CurrentScale /> */}
                <ZoomPopover />
                <ZoomIn>
                  {(p: RenderZoomInProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  )}
                </ZoomIn>
                <Download>
                  {(p: RenderDownloadProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                </Download>
                <Print>
                  {(p: RenderPrintProps) => (
                    <IconButton
                      onClick={p.onClick}
                      sx={{
                        borderRadius: 2,
                      }}
                    >
                      <PrintIcon />
                    </IconButton>
                  )}
                </Print>
              </Grid>
            </Fab>
          </Rnd>

          <Viewer
            fileUrl={fileContent}
            theme={theme === THEMES.DARK ? "dark" : "light"}
            plugins={[
              // defaultLayoutPluginInstance,
              // readingIndicatorPluginInstance,
              getFilePluginInstance,
              // fullScreenPluginInstance,
              pageNavigationPluginInstance,
              printPluginInstance,
              searchPluginInstance,
              highlightPluginInstance,
              zoomPluginInstance,
              // thumbnailPluginInstance,
            ]}
            setRenderRange={(visiblePageRange) => {
              return { endPage: visiblePageRange?.numPages, startPage: 0 };
            }}
            defaultScale={1}
            // defaultScale={SpecialZoomLevel.PageFit}
            pageLayout={pageLayout}
            enableSmoothScroll={true}
            // renderLoader={() => <CircularProgress />}
            renderLoader={(percentages: number) => (
              <div style={{ width: "240px" }}>
                <LinearProgress
                  variant="determinate"
                  value={Math.round(percentages)}
                />
              </div>
            )}
            scrollMode={ScrollMode.Vertical}
            onDocumentLoad={onDocumentLoadSuccess}
            onZoom={(newScale) => {
              setScale(newScale.scale);
            }}

            // renderLoader={(percentages: number) => (
            //   <div style={{ width: "240px" }}>
            //   <ProgressBar progress={Math.round(percentages)} />
            //     <LinearProgress
            //       variant="determinate"
            //       value={Math.round(percentages)}
            //     />
            //   </div>
            // )}
          />
        </Box>

        <Dialog onClose={handleCloseSignaturePad} open={showSignaturePad}>
          <AIDocumentSignature
            onSign={handleSave}
            onCancel={handleCloseSignaturePad}
          />
        </Dialog>
      </div>
    </Worker>
  );
}

export default ReactPDFViewer;
