export type ChatBoxType = {
  id: string;
  firstMessage: string;
  createdAt: string;
};

export enum ChatType {
  GENERAL = "general",
  CONTRACT = "contract",
  SUGGESTED_VENDORS = "searchVendor",
  SUGGESTED_CONTRACTS = "searchContract",
}

export type ChatMessageType = {
  type: ChatType;
  author: Author;
  message: string;
  createdAt: string | number;
  listofChoices?: ContractChoice[] | VendorChoice[];
};

export type ContractChoice = string[];

export type VendorChoice = {
  vendorID: string;
  email: string;
  description: string;
  website: string;
  address: string;
};

export enum TabType {
  home = "home",
  history = "history",
  chatBox = "chatBox",
}

export type IChatBotHistory = {
  id: string | null;
  content: ChatMessageType[];
  contractId: string | undefined;
  vendorId: string | undefined;
};

export type Annotation = {
  type: string;
  text: string;
  start_index: number;
  end_index: number;
  file_citation: any;
};

export type AssistantMessage = {
  id: string;
  object: string;
  created_at: number;
  assistant_id: string;
  thread_id: string;
  run_id: string;
  role: string;
  content: {
    type: string;
    text: {
      value: string;
      annotations: Annotation[];
    };
  }[];
  attachments: any[];
  metadata: any;
};

export type AssistantThread = {
  id: string;
  contractId?: string;
  userId: string;
  vendorId?: string;
  modified: number;
  thread: {
    id: string;
    object: string;
    created_at: number;
    metadata: any;
    tool_resources: any;
  };
  messages: AssistantMessage[];
};

export type IAResponse = {
  id: string;
  aiRes: ChatMessageType;
};

export enum Author {
  BOT = "bot",
  USER = "user",
}

export type NewChatBoxMessage = {
  type: ChatType;
  message: string;
};
