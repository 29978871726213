import styled from "@emotion/styled";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Card as MuiCard,
  Typography as MuiTypography,
  Stack,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useState } from "react";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 10px;
  // background-color: ${(props) => props.theme.palette.neutral.primary};
  // background-color: ${(props) => props.theme.palette.common.white};
  // background: ${(props) => props.theme.palette.primary.main};
  // background: ${(props) => props.theme.palette.primary.gradient};
  // background-image: url("/static/img/backgrounds/dark-raster.png");
  // background-position: top center;
  // background-size: initial;
`;
const TypographyTitle = styled(MuiTypography)`
  // color: ${(props) => props.theme.palette.common.white};
`;
const NewChatBoxButton = styled(Button)`
  height: 60px;
`;

// Main Component
type FollowUpQuestionsProps = {
  maxHeight?: number;
  onClick: (message: string) => void;
  questions: string[];
  max?: number;
  showMore?: boolean;
  disabled?: boolean;
};

const FollowUpQuestions = ({ ...props }: FollowUpQuestionsProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();

  const { questions } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 3;
  const numPages = Math.ceil(props.questions.length / pageSize);

  const handleClick = (question: string) => {
    props.onClick(question);
  };

  const handleNext = async () => {
    if (currentPage === numPages - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleBack = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" spacing={1} px={3}>
        {currentPage > 0 && (
          <Box alignContent="center">
            <IconButton onClick={handleBack} disabled={currentPage === 0}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </IconButton>
          </Box>
        )}
        <Box display="flex" m={4} gap={2} width="100%">
          <Box
            sx={{
              paddingBottom: 2,
              flexWrap: "wrap",
              justifyContent: "end",
              display: "flex",
            }}
          >
            {questions?.map((question, i: number) => {
              // if (i >= promptsToShow) {
              //   return null;
              // }

              if (
                i >= currentPage * pageSize &&
                i < (currentPage + 1) * pageSize
              ) {
                return (
                  <Chip
                    key={`question-${i}`}
                    label={question}
                    onClick={() => handleClick(question)}
                    disabled={props.disabled}
                    sx={{
                      m: 0.5,
                      py: 2,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                    }}
                    variant="outlined"
                    color="info"
                  />
                );
              }
            })}
          </Box>
        </Box>
        {currentPage < numPages - 1 && (
          <Box alignContent="center">
            <IconButton
              onClick={handleNext}
              disabled={currentPage === numPages - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default FollowUpQuestions;
